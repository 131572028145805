import React, { useState } from 'react';
import { Button, Container } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import './EmployeelnterestsQuestionComponent.css';
import { useMantineTheme } from '@mantine/core';
import { useNavigate } from "react-router-dom";
import SheetComponent from "../SheetComponent";
import Config from "../../../classes/application/Config";
import InterviewUserProfileModel from "../../../classes/interview/InterviewUserProfileModel";

const EmmployeeInterestsQuestionComponent: React.FC = () => {
    const { t } = useTranslation();
    const [selectedOptions, setSelectedOptions] = useState<Set<string>>(new Set());
    const [hoveredButton, setHoveredButton] = useState<string | null>(null); // Track which button is hovered
    const navigate = useNavigate();

    let pg="/app/interview/employee/interests";

    // Handle adding/removing options from selectedOptions
    const handleToggleOption = (option: string) => {
        setSelectedOptions(prevState => {
            const newState = new Set(prevState);
            if (newState.has(option)) {
                newState.delete(option);
            } else {
                newState.add(option);
            }
            return newState;
        });
    };

    const cfg = Config.getInstance();
    const isMobile = cfg.isMobileDevice();

    const intv = InterviewUserProfileModel.getInstance();
    intv.setLastPage(pg);

    const minWidth = isMobile ? '100%' : '300px';
    const minHeight = '150px';
    const buttonPadding = '0px';
    const maxWidthMobile = '95%';
    const maxWidthDesktop = '30%';

    const theme = useMantineTheme();
    const primaryColor = theme.colors[theme.primaryColor][6];

    // Get button background color depending on its state (hovered/selected)
    const getBackgroundColor = (option: string, selectedOptions: Set<string>): string => {
        if (selectedOptions.has(option)) {
            return primaryColor; // Selected button color
        }
        if (hoveredButton === option) {
            return '#dddddd'; // Hover effect color
        } else {
            return '#efefef'; // Default background color
        }
    };

    const options = ['looking_for_job', 'considering_vat'];

    // Handle confirming the selection
    const handleConfirm = () => {
       // alert(JSON.stringify(Array.from(selectedOptions)));
        intv.set_considerOpeningVat(false);
        intv.set_seeksJobs(false);
        let selectedValues = Array.from(selectedOptions);

        intv.set_isEmployee(true);
        intv.set_isFreelancer(false);
        intv.set_isCompany(false);
        intv.set_vatNumber("");
        intv.set_hasEmployees(false);

        selectedValues.forEach(option => {
            if (option === 'looking_for_job') {
                console.log('Looking for a job option selected.');
                intv.set_seeksJobs(true);

            } else if (option === 'considering_vat') {
                intv.set_considerOpeningVat(true);
                console.log('Considering VAT option selected.');
            } else {
                console.log(`Unknown option selected: ${option}`);
            }
        });

        intv.storeLocal();

        navigate("/app/interview/finished");

    };

    return (
        <SheetComponent title="" backto="/app/interview/vat">
            <Container
                style={{
                    textAlign: 'center',
                    width: "100vw",
                    maxWidth: "100%",
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                    backgroundColor: "#dddddd",
                    alignItems: 'center',
                    borderRadius: "15px",
                }}
            >
                <h2>{t('company_what_are_you_interested_in')}</h2>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap', // Allows buttons to wrap on smaller screens
                        justifyContent: 'center', // Keeps items centered
                        gap: '10px', // Adds spacing between buttons
                        width: '100%',
                        flexDirection: isMobile ? 'column' : 'row',
                        textAlign: 'center',
                        alignItems: 'center', // Centers child elements
                    }}
                >
                    {options.map(option => (
                        <Button
                            key={option}
                            onClick={() => handleToggleOption(option)}
                            className={`button company-${option}`}
                            onMouseEnter={() => setHoveredButton(option)} // Set hover to true for the current button
                            onMouseLeave={() => setHoveredButton(null)} // Reset hover when mouse leaves
                            style={{
                                width: isMobile ? maxWidthMobile : maxWidthDesktop,
                                maxWidth: isMobile ? maxWidthMobile : maxWidthDesktop,
                                minHeight: minHeight,
                                maxHeight: '150px',
                                minWidth: minWidth,
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                                textAlign: 'center',
                                padding: buttonPadding,
                                lineHeight: '1.6',
                                flex: '1 1 auto',
                                backgroundColor: getBackgroundColor(option, selectedOptions),
                                borderColor: 'white',
                                borderRadius: '10px',
                                marginBottom: '10px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
                                color: selectedOptions.has(option) || hoveredButton === option ? 'black' : 'black',
                            }}
                            variant={selectedOptions.has(option) ? 'filled' : 'outline'}
                        >
                            <div style={{ width: '100%', whiteSpace: 'pre-line' }}>
                                {t(`employee_${option}`)}
                            </div>
                        </Button>
                    ))}
                </div>

                {selectedOptions.size > 0 && (
                    <div style={{ marginTop: '20px', display:'none' }}>
                        <p>{t('company_selected_options')}:</p>
                        <ul style={{ paddingLeft: '20px', wordBreak: 'break-word' }}>
                            {Array.from(selectedOptions).map((option, index) => (
                                <li key={index} style={{ marginBottom: '10px' }}>
                                    {t(`employee_${option}`)}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <br />
                <Button
                    fullWidth
                    style={{
                        marginTop: '20px',
                        width: isMobile ? '95%' : '40%',
                        fontSize: "20px",
                        margin: "20px"
                    }}
                    disabled={selectedOptions.size === 0}
                    onClick={handleConfirm} // Add onClick handler to confirm
                >
                    {t('confirm_choices')}
                </Button>
            </Container>
        </SheetComponent>
    );
};

export default EmmployeeInterestsQuestionComponent;
