import {ArrayAccessor} from "../../lib/ArrayAccessor";
import Application from "../Application";

export class InterviewUserProfileModel extends ArrayAccessor {

    private static _instance: InterviewUserProfileModel | null = null;

    protected _last_page = "";


    public getLastPage(): string {
        return this._last_page;
    }

    public setLastPage(pg: string): void {
        this._last_page = pg;
    }


    public static getInstance(): InterviewUserProfileModel {
        if (!this._instance) {
            this._instance = new InterviewUserProfileModel();
        }
        this._instance.fetchFromLocal();
        return this._instance;
    }

    public storeLocal(page?: string) {
        if (!page) {
            page = this.getLastPage();
        }

        this.addArrVal("completed_pages", page, page);
    //    ww(this.getArr("completed_pages"));

        let json = JSON.stringify(this.getData());
        localStorage.setItem(this.getId(), json)
    }

    public fetchFromLocal(): any {
        const json = localStorage.getItem(this.getId());
        if (json) {
            this.setData(JSON.parse(json));
        }
        return this;
    }
    public fetchLocal(): boolean {
        let json = localStorage.getItem(this.getId());
        if (json) {
            this.setData(JSON.parse(json));
            return true;
        }
        return false;
    }

    public getId(): string {
        let um = Application.getInstance().getUserModel();
        if (um === undefined) {
            return "";
        }
        let id: string = "" + um.get_id() + "_interview";
        return id;
    }


    public get_hasVat(): boolean | null {
        return this.get("hasVat");
    }

    public set_hasVat(value: boolean | null): void {
        this.set("hasVat", value);
    }

    public get_vatNumber(): string | null {
        return this.get("vatNumber");
    }

    public set_vatNumber(value: string | null): void {
        this.set("vatNumber", value);
    }

    public get_isCompany(): boolean | null {
        return this.get("isCompany");
    }

    public set_isCompany(value: boolean | null): void {
        this.set("isCompany", value);
    }

    public get_isFreelancer(): boolean | null {
        return this.get("isFreelancer");
    }

    public get_isEmployee(): boolean | null {
        return this.get("isEmployee");
    }

    public set_isFreelancer(value: boolean | null): void {
        this.set("isFreelancer", value);
    }

    public set_isEmployee(value: boolean | null): void {
        this.set("isEmployee", value);
    }

    public get_hasEmployees(): boolean | null {
        return this.get("hasEmployees");
    }

    public set_hasEmployees(value: boolean | null): void {
        this.set("hasEmployees", value);
    }

    public get_considerOpeningVat(): boolean | null {
        return this.get("considerOpeningVat");
    }

    public set_considerOpeningVat(value: boolean | null): void {
        this.set("considerOpeningVat", value);
    }

    public get_considerToBeEmployed(): boolean | null {
        return this.get("considerToBeEmployed");
    }

    public set_considerToBeEmployed(value: boolean | null): void {
        this.set("considerToBeEmployed", value);
    }

    public get_seeksEmployees(): boolean | null {
        return this.get("seeksEmployees");
    }

    public set_seeksEmployees(value: boolean | null): void {
        this.set("seeksEmployees", value);
    }

    public get_publishJobs(): boolean | null {
        return this.get("publishJobs");
    }

    public set_publishJobs(value: boolean | null): void {
        this.set("publishJobs", value);
    }

    public set_hiresEmployees(value: boolean | null): void {
        this.set("hiresEmployees", value);
    }

    public get_hiresEmployees(): boolean {
        return this.get("hiresEmployees", false);
    }


    public set_seeksJobs(value: boolean | null): void {
        this.set("seeksJobs", value);
    }

    public get_seeksJobs(): boolean {
        return this.get("seeksJobs", false);
    }

    public get_outsourcesEmployees(): boolean | null {
        return this.get("outsourcesEmployees");
    }

    public set_outsourcesEmployees(value: boolean | null): void {
        this.set("outsourcesEmployees", value);
    }

    public get_hasRoleB2B(): boolean | null {
        return this.get("hasRoleB2B");
    }

    public set_hasRoleB2B(value: boolean | null): void {
        this.set("hasRoleB2B", value);
    }

    public get_hasRoleBtcCustomer(): boolean | null {
        return this.get("hasRoleBtcCustomer");
    }

    public set_hasRoleBtcCustomer(value: boolean | null): void {
        this.set("hasRoleBtcCustomer", value);
    }

    public get_hasRoleBtcBusiness(): boolean | null {
        return this.get("hasRoleBtcBusiness");
    }

    public set_hasRoleBtcBusiness(value: boolean | null): void {
        this.set("hasRoleBtcBusiness", value);
    }

    public sampleProfile(): void {
        this.set_hasVat(Math.random() > 0.5);
        this.set_vatNumber("IT" + Math.floor(Math.random() * 1000000000).toString());
        this.set_isCompany(Math.random() > 0.5);
        this.set_isFreelancer(Math.random() > 0.5);
        this.set_hasEmployees(Math.random() > 0.5);
        this.set_considerOpeningVat(Math.random() > 0.5);
        this.set_considerToBeEmployed(Math.random() > 0.5);
        this.set_seeksEmployees(Math.random() > 0.5);
        this.set_publishJobs(Math.random() > 0.5);
        this.set_outsourcesEmployees(Math.random() > 0.5);
        this.set_hasRoleB2B(Math.random() > 0.5);
        this.set_hasRoleBtcCustomer(Math.random() > 0.5);
        this.set_hasRoleBtcBusiness(Math.random() > 0.5);
        this.setData(this.getData());

    }
}

export default InterviewUserProfileModel;
