import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import {
    AppShell,
    Box,
    LoadingOverlay,
    useMantineColorScheme,
    useMantineTheme,
} from "@mantine/core";
import {useDisclosure, useViewportSize} from "@mantine/hooks";
import Sidebar from "src/components/Sidebar";
import {Outlet} from "react-router-dom";
import Header from "./Header";
import {layoutConst} from "src/constants/layout.const";
import usePing from "src/hooks/usePing";
import useIsMobile from "src/hooks/useIsMobile";
import useAuth from "src/hooks/useAuth";
import useScrollTop from "src/hooks/useScrollTop";
import SupportLinks from "./SupportLinks";
import Config from "../classes/application/Config";
import PublicSidebar from "../components/PublicSidebar";
import Application from "../classes/Application";
import PublicAccessModal from "../modules/public/components/modals/PublicAccessModal";
import {useEffect, useState} from "react";
import {Url} from "../lib/http/Url";
import GA4Tracker from "../components/GA4Tracker";

function PublicLayout() {
    usePing();
    useScrollTop();
    const {width} = useViewportSize();
    const [mobileOpened, {toggle: toggleMobile}] = useDisclosure();
    const [desktopOpened, {toggle: toggleDesktop}] = useDisclosure();
    const xlScreenSize = width > layoutConst.XL_SCREEN_SIZE;
    const {email} = useAuth();
    const theme = useMantineTheme();
    const {colorScheme, setColorScheme} = useMantineColorScheme();

    setColorScheme("light");

    const bgColor =
        colorScheme === "light" ? theme.colors.gray[2] : theme.colors.gray[8];

    let isMobile = useIsMobile();
    if (isMobile == undefined) {
        isMobile = false;
    }
    let showBurger: boolean = isMobile;

    const headerHeight = layoutConst.HEADER_HEIGHT;
    const toggleOnMobile = () => {
        if (isMobile) {
            toggleMobile();
        }
    };
    const mainHeight = `calc(100vh - ${headerHeight}px)`;
    const mainWidth = xlScreenSize
        ? `calc(100vw - ${layoutConst.SIDEBAR_WIDTH})`
        : "100%";

    const [showModal, setShowModal] = useState(false);

    // Check localStorage for the 'public_modal_ok' key and show modal after 2 seconds
    useEffect(() => {
        const modalKey = "public_modal_ok";

        if (!localStorage.getItem(modalKey)) {
            const timer = setTimeout(() => {

                setShowModal(true);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, []);

    function shouldCollapseNavbar(): boolean {
        if (isMobile && !mobileOpened) {
            return true;
        }
        return false;
    }

    let cfg = Config.getInstance();

    function shoulWait(): boolean {
        if (cfg.isCurrentPublicUrl()) {
            return false;
        }
        if (!email) {
            return true;
        }
        return false;
    }

    let isAuth = Application.getInstance().isAuth();
    showBurger = false;
    if (isAuth) {
        location.href = "/app";
    }


    if (isMobile) {
        showBurger = true;
    }
    if (Url.fromBrowser().queryContains("testmodal")) {
        localStorage.removeItem("public_modal_ok");
    }

    return (
        <>
            <GA4Tracker trackingId=""/>

            <AppShell
                navbar={{
                    width: isMobile ? "" : layoutConst.SIDEBAR_WIDTH,
                    breakpoint: isMobile ? layoutConst.SIDEBAR_BREAKPOINT : 300,
                    collapsed: {
                        mobile: shouldCollapseNavbar(),
                        desktop: false,
                    },
                }}
                header={{
                    height: headerHeight,
                }}
                py="md"
                bg={bgColor}
            >
                <AppShell.Header pr={isMobile ? 0 : "md"} withBorder={false} bg={bgColor}>
                    <Header
                        showBurger={showBurger}
                        toggleDesktop={toggleDesktop}
                        toggleMobile={toggleMobile}
                    />
                </AppShell.Header>

                <AppShell.Navbar onClick={toggleOnMobile} withBorder={false} bg="transparent">
                    <PublicSidebar/>
                </AppShell.Navbar>

                <AppShell.Main h={mainHeight} w={mainWidth}>
                    <Box bg={bgColor} mt="md">
                        <LoadingOverlay
                            visible={shoulWait()}
                            zIndex={1000}
                            overlayProps={{radius: "sm", blur: 2}}
                        />
                        <Box mt={isMobile ? 80 : 0} mx={isMobile ? "xs" : "md"}>
                            <Outlet/>
                        </Box>
                    </Box>

                    {/* Display PublicAccessModal if needed */}
                    {showModal && (
                        <PublicAccessModal
                            isOpen={showModal}  // Pass the showModal state here
                            onClose={() => {
                                localStorage.setItem("public_modal_ok", "true");
                                setShowModal(false);
                            }}
                        />
                    )}

                    {/* support button */}
                    <SupportLinks small/>
                </AppShell.Main>
            </AppShell>
        </>
    );
}

export default PublicLayout;
