import {ApiServiceXmlHttp} from "../../../lib/http/Apiservice/ApiServiceXmlHttp";
import {ServiceResponse} from "../../../lib/http/ServiceResponse";
import {ApiServices} from "../../../lib/http/ApiServices";
import InterviewUserProfileModel from "../InterviewUserProfileModel";
import {InterviewPutServiceResponse} from "./put/InterviewPutServiceResponse";

export class InterviewService extends ApiServiceXmlHttp {

    /*
    constructor() {
        super("");
    }
    */

    public async storeInterview(intv: InterviewUserProfileModel): Promise<InterviewPutServiceResponse> {

        this.setToken(this.getToken());
        let resp = new InterviewPutServiceResponse();



        await this.putSafe(`interview/storeinterview`, intv.getData(), resp);

        return resp;
    }


}

export default InterviewService;
