import {
    ActionIcon,
    Box,
    Button,
    Flex,
    Group,
    Paper,
    Text,
    Title,
} from "@mantine/core";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "src/components/Loader";
import getTypeAttributes from "src/helpers/getTypeAttributes";
import { useContext, useEffect, useMemo } from "react";
import { NotificationContext } from "src/providers/notification.provider";
import { useTranslation } from "react-i18next";

export default function NotificationView() {
    const { notificationId } = useParams();
    const navigate = useNavigate();
    const { getById, isLoading, error, markAsRead } = useContext(NotificationContext);
    const notification = getById(notificationId);
    const { t } = useTranslation();

    const created = useMemo(
        () =>
            notification?.meta_created_at
                ? new Date(notification?.meta_created_at).toLocaleString()
                : "",
        [notification?.meta_created_at]
    );

    useEffect(() => {
        if (notificationId && !notification?.read) {
            markAsRead(notificationId);
        }
    }, [notificationId, notification, markAsRead]);

    if (!notificationId || !notification) {
        return (
            <Box miw={300} maw={800} p="md">
                <Title order={3}>{t("nothing_to_see")}</Title>
                <Text>{t("notification_id_not_found")}</Text>
                <Button variant="default" onClick={() => navigate("/app/notifications")}>
                    {t("back")}
                </Button>
            </Box>
        );
    }

    let { title, type, desc, descFull, link } = notification;
    const { icon, color } = getTypeAttributes({ type });

    let targetObject = null;
    if (desc?.includes("||")) {
        let [descText, jsonData] = desc.split("||");
        desc = descText;
        try {
            targetObject = JSON.parse(jsonData);
        } catch (error) {
            console.error("Failed to parse targetObject from desc:", error);
        }
    }

    if (descFull?.includes("||")) {
        let [descText, jsonData] = descFull.split("||");
        descFull = descText;
        try {
            targetObject = JSON.parse(jsonData);
        } catch (error) {
            console.error("Failed to parse targetObject from descFull:", error);
        }
    }

    // Cleaning up description text
    desc = desc.replace("FROM SEND ;", "").replace("gotten", "").replace(" ", "");

    if (targetObject?.proposal_id) {
        link = `/app/view/proposal/${targetObject.proposal_id}`;
    }
    if (targetObject?.application_id) {
        link = `/app/view/application/${targetObject.application_id}`;
    }


    return (
        <Paper maw={800} w="100%" p="md" mx="auto" withBorder shadow="lg">
            <Loader isLoading={isLoading} error={error}>
                <Flex direction="column" m="md">
                    <Group wrap="nowrap">
                        <ActionIcon radius="xl" color={color}>
                            {icon}
                        </ActionIcon>
                        <Title order={3}>{t(title)}</Title>
                    </Group>
                    <Flex justify="end" w="100%">
                        <Text size="xs" c="grey">{created}</Text>
                    </Flex>
                    <Flex direction="column" mih={150} mt="xl">
                        <Text>{t(desc)}</Text>
                    </Flex>
                    <Flex justify="space-between" mt="xl">
                        <Button variant="default" onClick={() => navigate("/app/notifications")}>
                            {t("close")}
                        </Button>
                        {link && (
                            <Button variant="filled" onClick={() => link && navigate(link)}>
                                {t("view")}
                            </Button>
                        )}
                    </Flex>
                </Flex>
            </Loader>
        </Paper>
    );
}
