export class UTMHandler {
    protected static _instance: UTMHandler;
    private static _validity_period: number = 1 * 24 * 60 * 60 * 1000;

    protected constructor() {}

    public static getInstance(): UTMHandler {
        if (!UTMHandler._instance) {
            UTMHandler._instance = new UTMHandler();
        }
        return UTMHandler._instance;
    }

    public getValidityPeriod(): number {
        return UTMHandler._validity_period;
    }

    public setValidityPeriod(value: number): void {
        if (value <= 0) {
            throw new Error('Validity period must be a positive number.');
        }
        UTMHandler._validity_period = value;
    }

    public saveUTMParams(): void {
        const urlParams = new URLSearchParams(window.location.search);

        const utmParams: Record<string, string | null> = {
            utm_source: urlParams.get('utm_source'),
            utm_medium: urlParams.get('utm_medium'),
            utm_campaign: urlParams.get('utm_campaign'),
            utm_term: urlParams.get('utm_term'),
            utm_content: urlParams.get('utm_content'),
            utm_ref: document.referrer
        };

        const timestamp = new Date().toISOString();

        const utmData = { ...utmParams, timestamp };

        localStorage.setItem('utm_data', JSON.stringify(utmData));
    }

    public saveIfNeeded(): void {
        const currentCampaign = new URLSearchParams(window.location.search).get('utm_campaign');
        const existingData = localStorage.getItem('utm_data');

        if (!existingData) {
            this.saveUTMParams();
            return;
        }

        const { utm_campaign, timestamp } = JSON.parse(existingData);
        const isValid = this.isUTMValid();

        if (!isValid || utm_campaign !== currentCampaign) {
            this.saveUTMParams();
        }
    }

    public isUTMValid(): boolean {
        const utmData = localStorage.getItem('utm_data');
        if (!utmData) return false;

        const { timestamp } = JSON.parse(utmData);
        const storedTimestamp = new Date(timestamp).getTime();
        const currentTime = new Date().getTime();

        return currentTime - storedTimestamp <= UTMHandler._validity_period;
    }

    public getUTMParams(): Record<string, string | null> {
        if (!this.isUTMValid()) {
            this.clearUTMParams();
            return {};
        }

        const utmData = localStorage.getItem('utm_data');
        const utmParams = utmData ? JSON.parse(utmData) : {};

        // Retrieve GA4 session dimensions from sessionStorage, fallback to default values
        const ga4Dimensions = {
            'session_source': sessionStorage.getItem('utm_source') || 'direct',
            'session_medium': sessionStorage.getItem('utm_medium') || 'none',
            'session_campaign': sessionStorage.getItem('utm_campaign') || 'unknown',
            'session_manual_term': sessionStorage.getItem('utm_term') || '',
            'session_manual_ad_content': sessionStorage.getItem('utm_content') || ''
        };

        return { ...utmParams, ...ga4Dimensions };
    }

    public clearUTMParams(): void {
        localStorage.removeItem('utm_data');
    }
}
