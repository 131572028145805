import {ArrayAccessor} from "../lib/ArrayAccessor";

export class UserModel extends ArrayAccessor {
    public get_id(): string {
        return this.get("id");
    }

    constructor(data?:any) {
        super();
    }

    public set_id(id: string): void {
        this.set("id", id);
    }


    public hasInterview():boolean {
        let data=this.getData();
        if(!data) {
            return false;
        }
        if(!data.has_interview) {
            return false;
        }
        return data.extra.has_interview;
    }

    public get_meta_created_by(): string {
        return this.get("meta_created_by");
    }

    public set_meta_created_by(metaCreatedBy: string): void {
        this.set("meta_created_by", metaCreatedBy);
    }

    public get_meta_updated_by(): string {
        return this.get("meta_updated_by");
    }

    public set_meta_updated_by(metaUpdatedBy: string): void {
        this.set("meta_updated_by", metaUpdatedBy);
    }

    public get_meta_updated_at(): string {
        return this.get("meta_updated_at");
    }

    public set_meta_updated_at(metaUpdatedAt: string): void {
        this.set("meta_updated_at", metaUpdatedAt);
    }

    public get_meta_created_at(): string {
        return this.get("meta_created_at");
    }

    public set_meta_created_at(metaCreatedAt: string): void {
        this.set("meta_created_at", metaCreatedAt);
    }

    public get_meta_active(): boolean {
        return this.get("meta_active");
    }

    public set_meta_active(metaActive: boolean): void {
        this.set("meta_active", metaActive);
    }


    public get_email(): string {
        return this.get("email");
    }

    public set_email(email: string): void {
        this.set("email", email);
    }

    public get_phone(): string {
        return this.get("phone");
    }

    public set_phone(phone: string): void {
        this.set("phone", phone);
    }

    public get_name(): string {
        return this.get("name");
    }

    public set_name(name: string): void {
        this.set("name", name);
    }

    public get_surname(): string {
        return this.get("surname");
    }

    public set_surname(surname: string): void {
        this.set("surname", surname);
    }

    public get_profilePic(): string {
        return this.get("profilePic");
    }

    public set_profilePic(profilePic: string): void {
        this.set("profilePic", profilePic);
    }

    public get_role(): string {
        return this.get("role");
    }

    public set_role(role: string): void {
        this.set("role", role);
    }

    public get_companyId(): string {
        return this.get("companyId");
    }

    public set_companyId(companyId: string): void {
        this.set("companyId", companyId);
    }


}