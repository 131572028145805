import { useEffect, useState } from "react";
import { ApiService } from "../../../../lib/http/ApiService";
import { ApiServices } from "../../../../lib/http/ApiServices";
import Application from "../../../../classes/Application";

const CompanyFixer = () => {
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        let fetchCount = 0;  // To track how many times the fetch has been performed

        const fetchData = async () => {
            try {
                const aps: ApiService = ApiServices.getInstance();
                let app = Application.getInstance();
                let user = app.getUser();

                // If there is no user, do nothing and return early
                if (!user) {
                    return;
                }

                await aps.get("company/fix/" + user.id);
                setError(null);
            } catch (err) {
                setError("Failed to fix company");
            }
        };

        const interval = setInterval(() => {
            fetchCount++;

            fetchData();

            // Set the interval to 1 minute from the start onward
            if (fetchCount >= 1) {
                clearInterval(interval);
                setInterval(fetchData, 60000);  // 1 minute from the first fetch
            }
        }, 60000);  // 1 minute interval

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div style={{ display: "None" }}>
                {error && <p style={{ color: "red" }}>{error}</p>}
            </div>
        </>
    );
};

export default CompanyFixer;
