import Company from "src/types/Company.type";
import SimpleTable from "./SimpleTable";
import MatchTableRow from "./rows/MatchesTable.Row";
import SimpleTableMatches from "./SimpleTableMatches";
import SimpleTableMatchesMobile from "./SimpleTableMatchesMobile";
import Config from "../../classes/application/Config";

interface Props {
  // data?: (Partial<Company> & { id: string })[];
    data?:any
}
//alert(JSON.stringify(data))
const cols = ["", "name", "vat", "type", "size", "contacts"];
function MatchesTable({ data = [] }: Props) {

 //   ww(data);

    let cfg = Config.getInstance();
    let isMobile = cfg.isMobileDevice();
    if(false && isMobile) {

        return (
            <>
                <SimpleTableMatchesMobile

                    section="match"
                    cols={cols}
                    rows={data}
                    Row={MatchTableRow}
                    readOnly

                />
            </>
        );
    }

    return (
        <>
            <SimpleTableMatches

                section="match"
                cols={cols}
                rows={data}
                Row={MatchTableRow}
                readOnly

            />
        </>
    );
}

export default MatchesTable;
