import React from "react";
import { Table } from "@mantine/core";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import Application from "../../../classes/Application";
import "./MatchesJobDetail.css"; // Import CSS file

const MatchesJobDetail = ({ item }: any) => {
    const job = item?.itemData?.job;

    if (!job) return null;

    let ap = Application.getInstance();
    let uc: any = ap.getUserCompany();
    let user: any = ap.getUser();

    const job_owner = job.meta_created_by === user.id ? "me" : "party";
    const isJobOwnerMe = job_owner === "me";

    return (
        <Table>
            <thead>
            <tr>
                <th></th> {/* Arrow Column */}
                <th>Job Owner</th>
                <th>Job ID</th>
                <th>User ID</th>
                <th>UComp ID</th>
                <th>Created By</th>
                <th>Title</th>
                <th>Job Title</th>
                <th>Level</th>
                <th>Status</th>
                <th>Hourly Rate</th>
                <th>Costs Included</th>
                <th>Expiry Date</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className={`job-arrow ${isJobOwnerMe ? "arrow-left" : "arrow-right"}`}>
                    {isJobOwnerMe ? <IconArrowLeft size={24} /> : <IconArrowRight size={24} />}
                </td>
                <td className={isJobOwnerMe ? "job-owner-me" : "job-owner-party"}>
                    {job_owner}
                </td>
                <td>{job.id}</td>
                <td>{user.id}</td>
                <td>{uc.id}</td>
                <td>{job.meta_created_by}</td>
                <td>{job.title}</td>
                <td>{job.jobTitle}</td>
                <td>{job.level}</td>
                <td>{job.status}</td>
                <td>{job.hourlyRate}</td>
                <td>{job.costsIncluded ? "Yes" : "No"}</td>
                <td>{new Date(job.meta_expiry_date).toLocaleString()}</td>
            </tr>
            </tbody>
        </Table>
    );
};

export default MatchesJobDetail;
