import React, { useState } from "react";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { ConfirmDialogParams } from "../ConfirmDialog/ConfirmDialogParams";
import { useTranslation } from "react-i18next";
import "./CompanyAddedInfoDialog.css";
import appRouter from "../../../../classes/AppRouter";
import InfoDialog from "../InfoDialog/InfoDialog";
import {InfoDialogParams} from "../InfoDialog/InfoDialogParams";

interface RequiredCompanyConfirmDialogProps {
    params: InfoDialogParams;
    isOpen?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
}

const CompanyAddedInfoDialog: React.FC<RequiredCompanyConfirmDialogProps> = ({
                                                                                       params,
                                                                                       isOpen: externalIsOpen,
                                                                                       onConfirm,
                                                                                       onCancel
                                                                                   }) => {
    const { t } = useTranslation();
    const [internalIsOpen, setInternalIsOpen] = useState(true);

    // Use either the external isOpen prop or the internal state
    const isOpen = externalIsOpen ?? internalIsOpen;

  //  alert(t("insert_your_company"));
    // Set default params if not set
    params.setTitle(t("company_added"));
    params.setMessage(t("the_company_is_added"));
    params.setConfirmButtonLabel(t("ok"));

    let onconf= params.getOnConfirm();
    params.setOnConfirm(()=> {

        if(onconf) {
            onconf();
        }

        let fun = params.getCloseModal();


        if(fun) {
            fun();
        }
        let rtr = appRouter.getInstance();
   //     rtr.setNextToUrl();
   //     rtr.setCancelToUrl();
   //     rtr.navigateTo("/company/register");
    });

    // Handle the confirm action
    const handleConfirm = () => {
        onConfirm?.();
        setInternalIsOpen(false); // Close the modal after confirmation
    };

    // Handle the cancel action
    const handleCancel = () => {
        onCancel?.();
        setInternalIsOpen(false); // Close the modal after cancellation
    };

    return <InfoDialog params={params} isOpen={isOpen} onClose={handleCancel} />;
};

export default CompanyAddedInfoDialog;
