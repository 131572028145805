import iconMaps from "./iconMaps";
import Config from "../classes/application/Config";
import { IconMessageCircle } from "@tabler/icons-react"; // Importing the chatbot icon


let invites_enabled = false;
let cfg = Config.getInstance();
let isPublic = cfg.isCurrentPublicUrl();
let mainLinks:any[] = [];
let dboard="app";
if(isPublic) {
  dboard="/public";
}
// Push elements one at a time
mainLinks.push({
  to: dboard,
  icon: iconMaps.company,
  label: "my_company",
  public: true
});

mainLinks.push({
  label: "my_employees",
  to: `/app/section/employees`,
});

mainLinks.push({
  id: "job_offers",
  label: "my_job_offers",
  to: `/app/section/jobs`,
});

mainLinks.push({
  id: "credits",
  label: "credits",
  to: "/app/credits",
});

mainLinks.push({
  label: "matches",
  to: `/app/section/matches`,
});

mainLinks.push({
  to: "",
  icon: iconMaps.proposals,
  isOpen: true,
  label: "activity",
  links: [
    {
      label: "proposals_sent",
      to: `/app/section/proposals_sent`,
    },
    {
      label: "proposals_received",
      to: `/app/section/proposals_received`,
    },
    {
      label: "cvs_sent",
      to: `/app/section/applications_sent`,
    },
    {
      label: "cvs_received",
      to: `/app/section/applications_received`,
    },
  ],
});

mainLinks.push({
  to: "/app/view/job",
  icon: iconMaps.searchJob,
  label: "find_jobs",
  classes: "tour_find_job",
  public: true,
});

mainLinks.push({
  to: "/app/view/employee",
  icon: iconMaps.searchEmployee,
  label: "find_employees",
  classes: "tour_find_employee",
  public: true,
});

mainLinks.push({
  to: "/chatbot",
  icon: iconMaps.chatbot,
  label: "chatbot",
  public: true,
});

if(invites_enabled) {
  mainLinks.push({
    label: "invites_request",
    to: "/app/section/invites",
    icon: iconMaps.inviteRequest,
  });

  mainLinks.push({
    label: "invite_company",
    to: "/app/invite",
    icon: iconMaps.history,
    disabled: true,
  });
}

export const altLinks:any = [
  {
    to: "/app/support",
    icon: iconMaps.support,
    label: "support",
  },
  {
    to: "/app/settings",
    icon: iconMaps.settings,
    label: "settings",
  },
];

mainLinks = mainLinks.filter(link => {
  if(link.id==="credits") {
    return cfg.hasFeatureCredits();
  }

  return true;
});

console.log("MAINNNN");
console.log(mainLinks);

export { mainLinks };
