import Loader from "src/components/Loader";
import ApplicationsTable from "src/components/tables/ApplicationsTable";
import Actions from "src/components/Actions";
import {useGET} from "src/hooks/useApi";
import Application from "src/types/Application.type";
import Config from "../../../classes/application/Config";
import ProposalTable from "../../../components/tables/ProposalTable";
import {Applicationx} from "../../../classes/Application";

const ListApplicationDone = () => {
    let {data, isLoading, error} = useGET<Application[]>(
        "account/company/applications_sent"
    );

    const cfg = Config.getInstance();
    let app = Applicationx.getInstance();
    if (cfg.hasProgressiveRegister() && !app.hasCompany()) {
        data=[];
        return (<>
                <Actions
                    title="cvs_sent"
                    infoboxCode="infobox_cvs_sent"
                />
                <ApplicationsTable data={data}/>
            </>
        );
    }

    return (
        <Loader isLoading={isLoading} error={error} hasData={!!data}>
            <Actions
                title="cvs_sent"
                infoboxCode="infobox_cvs_sent"
            />
            <ApplicationsTable data={data}/>
        </Loader>
    );
};

export default ListApplicationDone;
