import Loader from "../Loader";
import { Flex, Pagination, Button } from "@mantine/core";
import { useState } from "react";
import EmployeeCard from "../cards/Employee.Card";
import Employee from "src/types/Employee.type";
import SearchFilter from "./SearchFilter";
import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";
import SearchNoResults from "./SearchNoResults";
import { SearchFilters } from "src/types/SearchFilters.type";
import { useGET } from "src/hooks/useApi";
import { PaginationResponse } from "src/types/Pagination.Type";
import Config from "../../classes/application/Config";
import usePublicApi, { usePublicGET } from "../../hooks/useApiPublic";
import { useTranslation } from 'react-i18next'; // Add i18next translation hook

function encodeFilters(filter: SearchFilters): string {
    return new URLSearchParams(filter as Record<string, string>).toString();
}

const SearchEmployees = () => {
    const { t } = useTranslation(); // Use the translation hook
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState<SearchFilters>({});
    const params = encodeFilters(filters);
    const url = `search/employees?page=${page}&${params}`;
    let data: PaginationResponse<Employee> | undefined;
    let isLoading = true;
    let error: any = null;
    let cfg = Config.getInstance();

    if (cfg.isCurrentPublicUrl()) {
        ({ data, isLoading, error } = usePublicGET<PaginationResponse<Employee>>(url, {
            isPublic: true,
        }));
    } else {
        ({ data, isLoading, error } = useGET<PaginationResponse<Employee>>(url, {
            isPublic: true,
        }));
    }

    const isPublic = cfg.isCurrentPublicUrl(); // ✅ Check if it's the public area


    return (
        <Flex direction="column">
            <SearchFilter setData={setFilters} />
            <Flex direction="column">
                <Loader isLoading={isLoading} error={error} hasData={data?.total > 0}>
                    <Flex direction="row" justify="center" align="top" wrap="wrap">
                        {_isEmpty(data?.result) && (
                            <SearchNoResults resetFilters={() => setFilters({})} />
                        )}

                        {_map(data?.result, (s: Employee, index: number) => (
                            <div
                                key={s.id}
                                style={{
                                    filter: cfg.isCurrentPublicUrl() && index >= 3 ? "blur(5px)" : "none",
                                    transition: "filter 0.3s ease-in-out",
                                    pointerEvents: cfg.isCurrentPublicUrl() && index >= 3 ? "none" : "auto"
                                }}
                            >
                                <EmployeeCard employee={s} />
                            </div>
                        ))}
                    </Flex>
                </Loader>
                <Flex direction="row" justify="center">
                    {!isPublic && <Pagination value={page} onChange={setPage} total={data?.count} />}
                </Flex>
            </Flex>

            {/* Floating Register Button for Public Area */}
            {cfg.isCurrentPublicUrl() && (
                <Button
                    onClick={() => {
                        window.open("/app/signup", "_self");
                    }}
                    size="xl"
                    variant="filled"
                    color="primary"
                    style={{
                        position: "fixed",
                        bottom: "40px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        zIndex: 1000,
                        fontWeight: "bold",
                        borderRadius: "50px",
                        padding: "16px 32px",
                        boxShadow: "0 6px 15px rgba(0, 0, 0, 0.2)",
                    }}
                >
                    {t('register_to_see_all')} {/* Use the translation key */}
                </Button>
            )}
        </Flex>
    );
};

export default SearchEmployees;
