import { useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import AdminView from "src/components/view/Admin.view";
import { useGET } from "src/hooks/useApi";
import Admin from "src/types/Admin.type";
import usePageView from "../../../hooks/usePageView";
import Application from "../../../classes/Application";

export default function ViewAdminPage() {
  const params = useParams();

  const url = `res/user/${params.id}`;
  const { data, isLoading, error } = useGET<Admin>(url);
//  alert(JSON.stringify(data));
  let app = Application.getInstance();
  //alert(JSON.stringify(app.getUser()));

  usePageView("admin");

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      <AdminView admin={data} />
    </Loader>
  );
}
