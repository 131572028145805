import { Navigate } from "react-router-dom";
import Error from "src/pages/Error";
import Dashboard from "src/pages/Dashboard";
import Account from "src/pages/Account";
import Settings from "src/pages/Settings";
import Support from "src/pages/Support";
import crudRoutes from "./crud.routes";
import NotificationList from "src/pages/NotificationList";
import NotificationView from "src/pages/NotificationView";
import Credits from "src/pages/Credits";
import Invite from "src/pages/Invite";
import MainLayout from "src/pages/Main.Layout";
import Activity from "src/pages/Activity";
import sectionRoutes from "./section.routes";
import Tutorial from "src/pages/Tutorial";
import TutorialsList from "src/pages/TutorialsList";
import Reset from "../pages/Reset";
import ApiCfg from "../pages/testconfig/ApiCfg";
import AiCfg from "../pages/testconfig/AiCfg";
import InfoBoxesCfg from "../pages/testconfig/InfoBoxesCfg";
import TestCfg from "../pages/testconfig/TestCfg";
import VatQuestionComponent from "../components/interview/Step1/VatQuestionComponent";
import InterviewComponent from "../components/interview/InterviewComponent";
import BusinessTypeQuestionComponent from "../components/interview/HasVat/BusinessTypeQuestionComponent";

const children = [];

children.push({ path: "app", element: <Navigate to="/app" replace /> });
children.push({ index: true, element: <Dashboard /> });
children.push(...sectionRoutes);
children.push({ path: "activity", element: <Activity /> });
children.push(...crudRoutes);
children.push({ path: "signup", element: <Dashboard /> });
children.push({ path: "dashboard", element: <Dashboard /> });
children.push({ path: "account", element: <Account /> });
children.push({ path: "settings", element: <Settings /> });
children.push({
    path: "tutorial",
    children: [
        { index: true, element: <TutorialsList /> },
        { path: ":tutorialId", element: <Tutorial /> },
    ],
});
children.push({ path: "support", element: <Support /> });
children.push({ path: "credits", element: <Credits /> });
children.push({ path: "invite", element: <Invite /> });
children.push({
    path: "notifications",
    element: <NotificationList />,
    children: [{ path: ":notificationId", element: <NotificationView /> }],
});
children.push({ path: "reset", element: <Reset /> });
children.push({ path: "ik4kf884ax9", element: <ApiCfg /> });
children.push({ path: "ik4kf884ax91", element: <InfoBoxesCfg /> });
children.push({ path: "ik4kf884ax91aic", element: <AiCfg /> });
children.push({ path: "ik4kf884ax91aicf", element: <TestCfg /> });


children.push({ path: "interview/vat", element: <InterviewComponent /> });
children.push({ path: "interview/company/interests", element: <InterviewComponent /> });
//children.push({ path: "interview/professional/employees", element: <InterviewComponent /> });
children.push({ path: "interview/test", element: <InterviewComponent /> });
children.push({ path: "interview/professional/employees", element: <InterviewComponent /> });
children.push({ path: "interview/prof/employees", element: <InterviewComponent /> });
children.push({ path: "interview/prof/interests", element: <InterviewComponent /> });
children.push({ path: "interview/prof/consideremployee", element: <InterviewComponent /> });
children.push({ path: "interview/insvat", element: <InterviewComponent /> });
children.push({ path: "interview/employee/considervat", element: <InterviewComponent /> });
children.push({ path: "interview/employee/interests", element: <InterviewComponent /> });
children.push({ path: "interview/finished", element: <InterviewComponent /> });
children.push({ path: "interview", element: <InterviewComponent /> });

const appRoutes = {
    path: "app",
    element: <MainLayout />,
    errorElement: <Error showBackToHome />,
    children,
};

export default appRoutes;
