import {Modal, Button, Text, Box} from "@mantine/core";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import "./PublicAccessModal.css";
import InfoBoxIcon from "../../../../components/ui/icon/InfoBoxIcon";
import useIsMobile from "../../../../hooks/useIsMobile";

const PublicAccessModal = ({isOpen, onClose}: { isOpen: boolean; onClose: () => void }) => {
    const {t} = useTranslation();


    const isMobile = useIsMobile();

    let styleGuest: any = {
        backgroundColor: "transparent",
        color: "black",
        border: "2px solid orange",
    }

    let styleRegister: any = {
        backgroundColor: "orange",
        color: "black",
        border: "2px solid orange",
    }
    if (isMobile) {
        styleGuest = {
            backgroundColor: "transparent",
            color: "black",
            border: "2px solid orange",
            textAlign: "center",
            maxWidth: "180px",
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "49%",
            height: "80px",
        }

        styleRegister = {
            backgroundColor: "orange",
            color: "black",
            border: "2px solid orange",
            textAlign: "center",
            maxWidth: "180px", // Adjust width as needed
            padding: "8px",
            display: "flex", // Ensure proper alignment
            flexDirection: "column", // Stack text if needed
            justifyContent: "center",
            width: "49%",
            height: "80px",
        }
    }

    useEffect(() => {
        if (isOpen) {
            // Use setTimeout to ensure the modal is fully rendered before applying the height adjustment
            setTimeout(() => {
                const modalContent = document.querySelector('.mantine-Modal-content');

                if (modalContent) {
                    // modalContent.style.height = 100; // Adjust height by -40px

                    //      alert(modalContent.style.height);
                }
            }, 0);
        }
    }, [isOpen]);

    return (
        <Modal
            opened={isOpen}
            onClose={onClose}
            title={null}
            centered
            size="lg"
            styles={{
                close: {
                    color: 'orange',
                    fontSize: '1.5rem',
                    borderRadius: '8%',
                    backgroundColor: 'transparent', // Transparent background
                    border: '2px solid orange', // Border for the close button
                    position: 'absolute', // Absolute positioning
                    top: '1px', // Distance from the top
                    right: '1px', // Distance from the right
                    zIndex: 90, // Ensure it appears on top of other elements
                },
            }}
        >
            <Box
                p={0}
                style={{
                    textAlign: 'center',
                    margin: 0,
                    position: 'relative', // Apply relative positioning
                    top: '-20px', // Move the content closer to the top of the modal
                    zIndex: 1000, // Ensure it appears on top of other elements
                }}
            >
                {/* Table structure with content */}
                <div style={{width: "100%"}}>
                        <div style={{display:"none",padding: "10px",position:"absolute",top:"-50px", left:"-30px"}} >
                            <InfoBoxIcon/>
                        </div>

                    <table
                        style={{
                            textAlign: 'justify',
                            width: "90%",
                            border: "2px solid orange", // Orange border for the table
                            borderCollapse: "collapse",
                            borderRadius: "12px",
                            backgroundColor: "white",
                            margin: "auto",
                            marginBottom: '16px',
                        }}
                    >
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td style={{padding: "10px"}}>
                                <Text
                                    style={{
                                        color: "#6b7280", // Neutral gray for secondary text
                                        fontSize: "1rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {t("Continue_guest_version")}
                                </Text>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Text
                style={{
                    color: "orange", // Orange text for emphasis
                    fontWeight: "bold",
                    fontSize: "1rem",
                    marginBottom: '16px',
                }}
            >
                {t("To use all functionalities, you are required to login or register")}
            </Text>

            <div style={{display: 'flex', justifyContent: 'center', gap: '10px'}}>
                {/* Register/Login button */}
                <Button
                    variant="outline"
                    size="md"

                    style={styleRegister}
                    onClick={() => {
                        window.location.href = "/app";
                    }}
                >
    <span style={{whiteSpace: "normal", wordBreak: "break-word", textAlign: "center"}}>

                        {t("Register or Login")}
    </span>

                </Button>

                {/* Continue as Guest button */}

                <Button
                    variant="outline"
                    size="md"
                    style={styleGuest}
                    onClick={onClose}
                >
    <span style={{whiteSpace: "normal", wordBreak: "break-word", textAlign: "center"}}>
        {t("continue_as_guest")}
    </span>
                </Button>

            </div>
        </Box>
</Modal>
)
    ;
};

export default PublicAccessModal;
