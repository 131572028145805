import { Flex, Paper, Stack, Title } from "@mantine/core";
import Proposal from "src/types/Proposal.type";
import Actions from "../Actions";
import { Row } from "../Row";
import JobOfferCard from "../cards/Job.Card";
import EmployeeCard from "../cards/Employee.Card";
import CompanyFromTo from "../CompanyFromTo";
import { ProposalStatus } from "src/types/ProposalStatus.type";
import useApi from "src/hooks/useApi";
import { useNavigate } from "react-router-dom";
import SeeMore from "../SeeMore";
import ProposalStatusPill from "../ProposalStatusPill";
import { useTranslation } from "react-i18next";
import ExpiryStatusPill from "../ExpiryStatusPill";
import Description from "../Description";
import useIsOwn from "src/hooks/useIsOwn";
import {ApiService} from "../../lib/http/ApiService";
import {ApiServices} from "../../lib/http/ApiServices";
import Application from "../../classes/Application";
import Config from "../../classes/application/Config";

interface Props {
    proposal: Proposal;
}

const ProposalView = ({ proposal }: Props) => {
    const { id, desc, status, toEmployee, JobOffer } = proposal;
    const isOwn = useIsOwn(proposal);

    const API = useApi();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const editProposal = async (newStatus: ProposalStatus) => {
        // API.PUT(`res/proposal/${id}`, { status: newStatus });

        let api = ApiServices.getInstance();
        api.setToken(Application.getInstance().getToken())

        try {
            await api.post(`res/proposal/changestatus/${id}`,{ status: newStatus });
        }
        catch (err:any) {

        }

        // fix this
        if (newStatus === ProposalStatus.accepted) {
            navigate(`/app/section/matches`);
        }
    };

    const actions = [];

    if (isOwn && status === ProposalStatus.draft) {
        actions.push({
            label: "send_proposal",
            onClick: () => editProposal(ProposalStatus.open),
        });
    }

    if (!isOwn) {
        actions.push({
            label: "decline_proposal",
            onClick: () => editProposal(ProposalStatus.denied),
            disabled: status !== ProposalStatus.open,
        });
    }


    if (!isOwn) {

        let disabled:boolean=status !== ProposalStatus.open;

        if(Config.getInstance().hasDebug()) {
            disabled=false;
        }
        actions.push({
            label: "accept_proposal",
            onClick: () => editProposal(ProposalStatus.accepted),
            disabled: disabled,
        });
    }
    //   ww(proposal);

    return (
        <Stack gap="md">
            <Title>{t("proposal")}</Title>

            <Row title={t("company")}>
                <Flex direction="column">
                    <Paper my="md">
                        <CompanyFromTo el={proposal} />
                    </Paper>

                    <SeeMore isOwn={isOwn} type="proposal" />
                </Flex>
            </Row>

            <Row title={t("job_offer")}>
                <JobOfferCard jobOffer={JobOffer} />
            </Row>

            <Row title={t("status")}>
                <ProposalStatusPill status={status} />
            </Row>

            {isOwn && (
                <Row title={t("expiry_date_title")}>
                    <ExpiryStatusPill item={proposal} type="proposal" />
                </Row>
            )}

            <Row title={t("description")}>
                <Description text={desc} />
            </Row>

            <Row title={t("proposed_employee")}>
                <EmployeeCard employee={toEmployee} />
            </Row>

            <Actions actions={actions} />
        </Stack>
    );
};

export default ProposalView;
