import React, { ReactNode } from 'react';
import { useMantineTheme } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconChevronLeft } from '@tabler/icons-react';
import { useNavigate } from "react-router-dom";

interface SheetComponentProps {
    title: string;
    backto?: string | null;
    children: ReactNode;
}

const SheetComponent: React.FC<SheetComponentProps> = ({ title, children, backto }) => {
    const theme = useMantineTheme();
    const { t } = useTranslation();

    let showTitle = true;
    if (title === "") {
        showTitle = false;
    }

    const navigate = useNavigate();
    // Back button handler
    const handleBackClick = () => {
        if (backto !== null && backto !== undefined) {
            navigate(backto);
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            {/* Back Button with the arrow and label */}
            {backto && (
                <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: '10px', left: '10px', cursor: 'pointer' }} onClick={handleBackClick}>
                    <IconChevronLeft size={32} />
                    <span style={{ marginLeft: '5px' }}>{t("back")}</span>
                </div>
            )}

            {/* Title Display */}
            {showTitle && (
                <h2 style={{ color: theme.colors.primary[6], marginLeft: '40px' }}>
                    {t(title)}
                </h2>
            )}

            {/* Children */}
            <div className="scont">
                {children}
            </div>
        </div>
    );
};

export default SheetComponent;
