import Loader from "../Loader";
import { Button } from "@mantine/core";
import JobOfferCard from "../cards/Job.Card";
import { Flex, Pagination } from "@mantine/core";
import JobOffer from "src/types/JobOffer.type";
import { JobFilters } from "src/types/JobFilters.type";
import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";
import SearchNoResults from "./SearchNoResults";
import { useGET } from "src/hooks/useApi";
import SearchFilter from "./SearchFilter";
import { PaginationResponse } from "src/types/Pagination.Type";
import Config from "../../classes/application/Config";
import { usePublicGET } from "../../hooks/useApiPublic";
import { ApiServices } from "../../lib/http/ApiServices";
import Application from "../../classes/Application";
import { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";


function encodeJobFilters(filter: JobFilters): string {
    if (_isEmpty(filter)) {
        return "";
    }
    return new URLSearchParams(filter as Record<string, string>).toString();
}

const SearchJobs = () => {
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState<JobFilters>({ ignoreAvailability: true });
    const params = encodeJobFilters(filters);
    const url = `search/jobs?page=${page}&${params}`;

    const navigate=useNavigate;
    let data: any | undefined;
    let isLoading = true;
    let error: any = null;
    let cfg = Config.getInstance();
    const isPublic = cfg.isCurrentPublicUrl(); // ✅ Check if it's the public area
    const {t} = useTranslation();

    if (isPublic) {
        useEffect(() => {
            const fetchData = async () => {
                isLoading = true;
                try {
                    const apis = ApiServices.getInstance();
                    apis.setToken(Application.getInstance().getToken());
                    const response = await apis.get(url);
                    data = response;
                } catch (err: any) {
                    // Handle error if needed
                } finally {
                    isLoading = false;
                }
            };
        }, [url]);

        ({ data, isLoading, error } = usePublicGET<PaginationResponse<JobOffer>>(url, {
            isPublic: true,
        }));
    } else {
        ({ data, isLoading, error } = useGET<PaginationResponse<JobOffer>>(url, {
            isPublic: true,
        }));
    }

    return (
        <Flex direction="column">
            <SearchFilter setData={setFilters} mode="jobOffer" />
            <Flex direction="column">
                <Loader isLoading={isLoading} error={error} hasData={data?.total > 0}>
                    <Flex direction="row" justify="center" align="top" wrap="wrap">
                        {_isEmpty(data?.result) && (
                            <SearchNoResults resetFilters={() => setFilters({})} />
                        )}
                        {_map(data?.result, (j: JobOffer, index: number) => (
                            <div
                                key={j.id}
                                style={{
                                    filter: isPublic && index >= 3 ? "blur(5px)" : "none", // 🔥 Blur only in public
                                    transition: "filter 0.3s ease-in-out",
                                    pointerEvents: isPublic && index >= 3 ? "none" : "auto"
                                }}
                            >
                                <JobOfferCard jobOffer={j} />
                            </div>
                        ))}
                    </Flex>
                </Loader>
                {!isPublic && <Pagination value={page} onChange={setPage} total={data?.count} />}

            </Flex>
            {/* 🔥 Floating "Register to See All" Button - Only in Public Area */}
            {isPublic && (
                <Button
                    onClick={() => {
                     window.open("/app/signup","_self")
                    }}
                    size="xl"
                    variant="filled"
                    color="primary"
                    style={{
                        position: "fixed",
                        bottom: "40px",
                        left: "50%",
                        transform: "translateX(-50%)", // Center the button
                        zIndex: 1000,
                        fontWeight: "bold",
                        borderRadius: "50px",
                        padding: "16px 32px",
                        boxShadow: "0 6px 15px rgba(0, 0, 0, 0.2)",
                    }}
                >
                    {
                        t("register_to_see_all")
                    }
                </Button>
            )}

        </Flex>
    );
};

export default SearchJobs;
