export class ArrayAccessor {
    protected _data: Record<string, any>;

    constructor(data: Record<string, any> = {}) {
        this._data = data;
    }

    public getData(): Record<string, any> {
        return this._data;
    }

    public getDataObject(): any {
        return this.getData().data;
    }
    addArrVal(arrkey: string, k: string, val: any): void {
        // Ensure arrkey exists and is an object, overwrite it if it's not an object
        if (!this.has(arrkey) || typeof this.get(arrkey) !== 'object' || Array.isArray(this.get(arrkey))) {
            this.set(arrkey, {});
        }

        // Add the key-value pair (tuple) to the object
        this.get(arrkey)[k] = val;
    }

    setData(value: any): void {
        this._data = value;
    }

    delArrVal(arrkey: string, k: string): void {
        if (!this.has(arrkey) || typeof this.get(arrkey) !== 'object' || Array.isArray(this.get(arrkey))) {
            throw new Error(`${arrkey} is not an object or doesn't exist.`);
        }

        const obj = this.get(arrkey);
        delete obj[k];
        this.set(arrkey, obj);
    }

    getArr(arrkey: string): any {
        if (this.has(arrkey) && typeof this.get(arrkey) === 'object' && !Array.isArray(this.get(arrkey))) {
            return this.get(arrkey);
        }
        return {};
    }

    get(key: string, defaultValue: any = null): any {
        return key in this._data ? this._data[key] : defaultValue;
    }

    set(key: string, value: any): void {
        this._data[key] = value;
    }

    has(key: string): boolean {
        return key in this._data;
    }

    remove(key: string): void {
        delete this._data[key];
    }

    public setArrVal(arrname: string, elem: any): void {
        if (!this.has(arrname) || typeof this.get(arrname) !== 'object' || Array.isArray(this.get(arrname))) {
            this.set(arrname, {});
        }
        this._data[arrname] = elem;
    }

    toJson(): Record<string, any> {
        return { ...this._data };
    }
}
