import { Stack, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import Company from "src/types/Company.type";
import CompanySection from "./Sections/Company.Section";
import useUser from "src/hooks/useUser";

interface Props {
  el: {
    CompanyFrom?: Company;
    CompanyTo?: Company;
  };
}

const CompanyFromTo = (props: Props) => {
  const { el } = props;
  const { t } = useTranslation();
  const { CompanyFrom, CompanyTo } = el;
  const { user } = useUser();
  let target = null;
  let title = null;

  if (CompanyFrom && CompanyFrom.id !== user.companyId) {
    target = CompanyFrom;
    title = t("from_company");
  }

  if (CompanyTo && CompanyTo.id !== user.companyId) {

    target = CompanyTo;
    title = t("to_company");
  }

  if (!target) {
    return null;
  }

  return (
    <Stack>
      <Title order={4}>{title}</Title>
      <CompanySection company={target} />
    </Stack>
  );
};

export default CompanyFromTo;
