import React, { useState } from 'react';
import { Button, Container, Stack, Group, TextInput } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import './BusinessInsVatQuestionComponent.css';
import Config from "../../../classes/application/Config";
import SheetComponent from "../SheetComponent";
import InterviewUserProfileModel from "../../../classes/interview/InterviewUserProfileModel";
import {useNavigate} from "react-router-dom";

const BusinessInsVatQuestionComponent: React.FC = () => {
    const { t } = useTranslation();
    const [vat, setVat] = useState('');
    const [error, setError] = useState('');

    let cfg = Config.getInstance();
    let isMobile = cfg.isMobileDevice();
    let backto: string = "/app/interview/company/interests";

    let intv = InterviewUserProfileModel.getInstance();
    backto = intv.getLastPage();
    const navigate = useNavigate();
    const handleVatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVat(event.target.value);
        setError('');
    };
    const validateVat = () => {
    //    alert(vat);
        if (!vat.trim()) {
            setError(t('main_vat_required'));
            return;

        } else if (vat.trim().length < 5) {
            setError(t('main_vat_required'));
            return;

        } else {
            setError('');
            // Submit logic here
        }
        intv.set_vatNumber(vat);

        intv.storeLocal();

       // ww(intv);

        navigate("/app/interview/finished");
    };

    return (
        <SheetComponent title="" backto={backto}>
            <Container
                style={{
                    textAlign: 'center',
                    width: "100vw",
                    maxWidth: "100%",
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                    backgroundColor: "#dddddd",
                    alignItems: 'center',
                    borderRadius: "15px",
                    padding: '20px',

                }}
            >
                <h2>{t('main_enter_vat')}</h2>
                <TextInput
                    value={vat}
                    onChange={handleVatChange}
                    error={error}
                    style={{ width: isMobile ? '90%' : '50%',maxWidth:"300px" }}
                />
                <Group mt="md">
                    <Button onClick={validateVat}>{t('confirm')}</Button>
                </Group>
            </Container>
        </SheetComponent>
    );
};

export default BusinessInsVatQuestionComponent;
