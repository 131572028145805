import Loader from "src/components/Loader";
import CompanyForm from "src/components/forms/CompanyXS.form";
import useApi from "src/hooks/useApi";
import useAuth from "src/hooks/useAuth";
import { useDetectLocale } from "src/hooks/useDetectLocale";
import Company from "src/types/Company.type";
import getUser from "src/utils/getUser";
import TrackingSystem from "../../../../../lib/Tracking/TrackingSystem";
import RegisterCompanyForm from "../RegisterCompanyForm/RegisterCompanyForm";
import appRouter from "../../../../../classes/AppRouter";
import {Url} from "../../../../../lib/http/Url";

interface Props {
    nextTab: () => void;
    isLoading: boolean;
}

export default function RegisterCompanyCreateComponent({ nextTab, isLoading }: Props) {
    const api = useApi();
    const { name, email, phone } = useAuth();
    const locale = useDetectLocale();

    /*
    window.onbeforeunload = function () {};
alert("didi");
   */
    window.onbeforeunload = function (event) {
        event.preventDefault();
 //       alert("ppp");
    };

    let u=Url.fromBrowser();


    const onSubmit = async (values: Partial<Company>, file?: File) => {

        // attach the image for uploadapp


        if(u.queryContains("testf")) {
            let rtr=appRouter.getInstance();
            //alert(rtr.getNextToUrl());
            rtr.navigateTo(rtr.getNextToUrl()+"?registration_completed");
            return;
        }






        if (file) {
            const res = await api.UPLOAD(file);
            values.profilePic = res.name;
        }
        // pass the ref id property from user to company
        const user = await getUser();
        values.ref = user.ref ?? undefined;

        const res = await api.POST(`account/company`, values);
        if (res?.success || res?.id) {

            TrackingSystem.getInstance().sendCustomEvent("company_registered");
            let rtr=appRouter.getInstance();

            //alert(rtr.getNextToUrl());
            rtr.navigateTo(rtr.getNextToUrl()+"?registration_completed");


            //  alert("iso");

            return ;


//            nextTab();
        }
    };

    const initialValues = {
        name: `${name}'s company`,
        contact_mail: email,
        contact_phone: phone,
        account_locale: locale,
    };

    if (isLoading) {
        return null;
    }

    return (
        <Loader isLoading={isLoading}>
            <RegisterCompanyForm onSubmit={onSubmit} initialValues={initialValues} />
        </Loader>
    );
}
