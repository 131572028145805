import { Table, Group, Text } from "@mantine/core";
import Employee from "src/types/Employee.type";
import { Fragment } from "react";
import Avatar from "src/components/Avatar";
import Availability from "src/components/Availability";
import Status from "src/components/badges/Status";
import FieldList from "src/components/badges/FieldList";
import Seniority from "src/components/badges/Seniority";
import { TableRow } from "src/types/TableRow.type";

// ck
const EmployeeTableRow = ({ item, onClick }: TableRow<Employee>) => {
  return (
    <Fragment key={item.id}>
      <Table.Td onClick={onClick}>
        <Avatar size={40} src={item.profilePic} />
      </Table.Td>
      <Table.Td onClick={onClick}>
        <Group gap="sm">
          <Text fw={700} tt="capitalize">

            {item.surname} {item.name}
          </Text>
        </Group>
      </Table.Td>
      <Table.Td onClick={onClick}>
        <Seniority level={item.level} />
      </Table.Td>
      <Table.Td onClick={onClick}>
        <FieldList list={item.fields} cap />
      </Table.Td>
      <Table.Td onClick={onClick}>
        <Availability from={item?.availableFrom} to={item?.availableTo} small />
      </Table.Td>
      <Table.Td onClick={onClick}>
        <Status item={item} />
      </Table.Td>
    </Fragment>
  );
};

export default EmployeeTableRow;
