import Config from "./application/Config";
import AppRouter from "./AppRouter";
import {ApiServices} from "../lib/http/ApiServices";
import {UserModel} from "../models/UserModel";

class Application {
    private static instance: Application;
    private readonly config: Config;
    protected env: any;

    protected _user: any;
    protected _userModel?: UserModel;


    protected _token: any;
    protected _cognito_user: any;


    protected _is_running_interview = false;



    public async waitForUserModel(): Promise<any> {
        return new Promise((resolve) => {
            const checkCondition = () => {
                const userModel = this.getUserModel();
                if (userModel !== null && userModel !== undefined) {
                    resolve(userModel);
                } else {
                    setTimeout(checkCondition, 100);
                }
            };
            checkCondition();
        });
    }

    public isRunningInterview(): boolean {
        return this._is_running_interview;
    }

    public runInterview(): void {
        this._is_running_interview = true;
    }

    public setToken(tok: any) {
        this._token = tok;
    }

    public setCognitoUser(user: any) {
        this._cognito_user = user;
    }

    public getCognitoUser(): any {
        return this._cognito_user;
    }

    public getUserModel():UserModel|undefined{
        return this._userModel;
    }

    public getToken() {
        return this._token;
    }

    private constructor(env = null) {
        if (env !== null) {
            this.env = env;
        }
        this._userModel=undefined;
        this._user = undefined;
        this.config = Config.getInstance(env);
    }

    public runOverlay(duration: number = 4000) {
        const overlay = document.getElementById("fade-overlay");
        if (overlay) {
            overlay.classList.add("opacity-100");
            overlay.classList.remove("opacity-0", "pointer-events-none");

            setTimeout(() => {
                overlay.classList.add("opacity-0");
                overlay.classList.remove("opacity-100");
            }, duration);
        }
    };


    public setUser(user: any) {


        let cfg = Config.getInstance();
        let isPublic = cfg.isCurrentPublicUrl();

        if (isPublic) {
            if (user) {
                let rtr = AppRouter.getInstance();
                rtr.redirectToAppIfNeeded(location.href);

                // alert("redir there");
            }
        }

        this._user = user;
        if(user!==undefined) {
            if(!this._userModel) {
                this._userModel=new UserModel();
            }
            this._userModel.setData(user);
            this.checkAppState();
        }



    }

    public getAppRouter():AppRouter {
        return AppRouter.getInstance();
    }

    public checkAppState() {
        //alert(JSON.stringify(this._userModel.getData().extra));
        if(!this.isRunningInterview()) {
                let um=this.getUserModel();
                if(um!==undefined && !um.hasInterview()) {
                    // alert("hve to route");
                    this.getAppRouter().navigateTo("/app/interview/vat");
                }
        }
    }


    protected _company: any = null;

    public async getUserCompany() {
        let id: string = this.getUser().id;

        let api = ApiServices.getInstance();
        api.setToken(this.getToken());

        if (this._company !== null) {
            return this._company;
        }
        let company: any = await api.get(`res/company/get/${id}`);

        this._company = company;
        return company;
    }

    public isAuth(): boolean {
        if (this._user !== undefined) {
            return true;
        }
        return false;
    }


    public hasCompany(): boolean {
        if (!this.getUser()) {
            return false;
        }
        return this.getUser().companyId;
    }

    public getUser(): any {
        return this._user;
    }

    public static getInstance(env: any = null): Application {
        if (!Application.instance) {
            Application.instance = new Application(env);
        }
        return Application.instance;
    }

    public static getConfig(): Config {
        return Application.getInstance().getConfig();
    }

    public getConfig(): Config {
        return this.config;
    }
}

export {Application as Applicationx};
export default Application;