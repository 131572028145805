import React, { useState, useEffect } from 'react';
import Modal from '../Modal/Modal';
import './InfoBox.css';
import './InfoBoxContent.css';
import InfoBoxIcon from "../../icon/InfoBoxIcon";
import Config from "../../../../classes/application/Config";
import InfoboxHandler from "../../../../classes/ui/InfoboxHandler.js";
import { ApiServices } from "../../../../lib/http/ApiServices.js";
import { ApiService } from "../../../../lib/http/ApiService";
import { useTranslation } from "react-i18next";

interface InfoBoxProps {
    code?: string;
    context?: string;
    title?: string;
    content?: string;
}

const InfoBox: React.FC<InfoBoxProps> = ({ code, title, content }) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isInfoboxVisible, setIsInfoboxVisible] = useState<boolean>(false);
    const [contents, setContents] = useState<any | null>(null);

    const cfg = Config.getInstance();
    const lang = cfg.getLanguage();
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (code) {
                    const aps: ApiService = ApiServices.getInstance();

                    const response = await aps.get(`infobox/get?lang=${lang}&code=${code}`);
                    setContents(response);
                }
            } catch (error) {
                console.error('Error fetching content:', error);
            }

            // Delay visibility toggle to trigger fade-in transition
            setTimeout(() => setIsInfoboxVisible(true), 10);
        };

        if (isModalOpen) {
            fetchData();
        } else {
            setIsInfoboxVisible(false);
        }
    }, [isModalOpen, code, lang]);

    const ih = InfoboxHandler.getInstance();
    if (contents) {
        ih.fillFromResponse(contents);
    }

    title = ih.getTitle();
    const cont = ih.getContent();

    const enabled = cfg.hasInfoBoxesEnabled();

    const toggleModal = () => {
        setIsModalOpen((prev) => !prev);
    };

    const internalCloseModal = () => {
        setIsModalOpen(false);
    };

    let txclose = t("close");

    console.log("fix");
    return (
        <>
            <Modal isOpen={isModalOpen} onClose={toggleModal}>
                <div className={`infobox-content ${isInfoboxVisible ? "show" : ""}`}>
                    <div className="infobox-header">
                        {enabled && <InfoBoxIcon />}
                        <h3>{title}</h3>
                    </div>

                    <div
                        className="infobox-body"
                        dangerouslySetInnerHTML={{ __html: cont }}
                    />
                    <button className="infobox-close" onClick={internalCloseModal}>
                        {txclose}
                    </button>
                </div>
            </Modal>

            {enabled && <InfoBoxIcon onClick={toggleModal} />}
        </>
    );
};

export default InfoBox;
