import {Fragment} from "react";
import {Anchor, Group, Table, Text} from "@mantine/core";
import Avatar from "src/components/Avatar";
import {InviteRequest} from "src/types/InviteRequest";
import {IconCheck} from "@tabler/icons-react";
import {TableRow} from "src/types/TableRow.type";

const InviteRequestTableRow = ({item, onClick}: TableRow<InviteRequest>) => (
    <Fragment key={item?.id}>
        <Table.Td onClick={onClick}>
            <Avatar size={26} src={item?.User?.profilePic}/>
        </Table.Td>
        <Table.Td onClick={onClick}>
            <Text fw={700} tt={"capitalize"}>
                {item?.User?.name} {item?.User?.surname}
            </Text>
        </Table.Td>
        <Table.Td onClick={onClick}>
            <Group>
                <Anchor href={`mailto:${item?.User?.email}`} size="sm">
                    {item?.User?.email}
                </Anchor>
                {item.User.account_verified_mail && <IconCheck color="green"/>}
            </Group>
        </Table.Td>
        <Table.Td onClick={onClick}>
            <Group>
                <Anchor href={`tel:${item?.User?.phone}`} size="sm">
                    {item?.User?.phone}
                </Anchor>
                {item.User.account_verified_phone && <IconCheck color="green"/>}
            </Group>
        </Table.Td>
        <Table.Td onClick={onClick}>{item.desc}</Table.Td>
    </Fragment>
);

export default InviteRequestTableRow;
