import _omit from "lodash/omit.js";
import { NumberInput, Stack, TextInput, Title } from "@mantine/core";
import FormSection from "src/components/forms/FormSection";
import Application from "src/types/Application.type";
import Actions from "../Actions";
import CompanyCard from "../cards/Company.Card";
import JobOfferCard from "../cards/Job.Card";
import SelectEmployeeTable from "../tables/SelectEmployeeTable";
import { useForm } from "@mantine/form";
import { ApplicationValidate } from "src/pages/data/validates/application.validate";
import useFormActions from "src/hooks/useFormActions";
import useUnloadAlert from "src/hooks/useUnloadAlert";
import { useTranslation } from "react-i18next";
import { META_FIELDS } from "src/constants/metaFields.const";
import DescriptionInput from "src/components/DescriptionInput";
import ActionsCheckCompany from "../ActionsCheckCompany";
import {ConfirmDialogParams} from "../ui/widget/ConfirmDialog/ConfirmDialogParams";
import {InfoDialogParams} from "../ui/widget/InfoDialog/InfoDialogParams";
import CompanyAddedInfoDialog from "../ui/widget/CompanyAddedInfoDialog/CompanyAddedInfoDialog";
import RequiredCompanyConfirmDialog from "../ui/widget/ProgressiveRegisterConfirmDialog/RequiredCompanyConfirmDialog";
import Config from "../../classes/application/Config";
import {Applicationx} from "../../classes/Application";
interface Props {
  title: string;
  initialValues?: Partial<Application>;
  onSubmit?: (values: Partial<Application>) => void;
  costId?: string;
  expiryId?: string;
}

export default function ApplicationForm({
  title,
  initialValues = {},
  onSubmit = () => {},
  costId,
  expiryId,
}: Props) {
  const { t } = useTranslation();



    let params = new ConfirmDialogParams();
    let ifparams = new InfoDialogParams();


  const form = useForm<Partial<Application>>({
    validateInputOnChange: true,
    initialValues,
    validate: ApplicationValidate,
    transformValues: (values) => ({
      ..._omit(values, [
        "JobOffer",
        "Employees",
        "CompanyFrom",
        "companyId",
        ...META_FIELDS,
      ]),
      price: Number(values.price),
      durationMax: Number(values.durationMax),
      durationMin: Number(values.durationMin),
    }),
  });
  useUnloadAlert();
  const formActions = useFormActions(
    form,
    onSubmit,
    undefined,
    costId,
    expiryId,
      true,
      t("application"),
      t("do_you_confirm_application")
);

    /**
     *  @todo check1 pierpa
     */
  //alert("in appform"+JSON.stringify(formActions));
    let info_opened=false;
    let isDialogOpen=false;


    const handleActionButtonClick = () => {
        const cfg = Config.getInstance();
        //    alert(params.getCloseModal());
        // let fun=params.getCloseModal();
       let app = Applicationx.getInstance();
        app.getUser()

        let fun = params.getOpenModal();
        if (cfg.hasProgressiveRegister() && !app.hasCompany()) {

            if (fun) {
                fun();
            }
            //     setIsDialogOpen(true); // Open the confirmation dialog if progressive registration is needed
        } else {
            //     window.location.href = actionSubmit.to; // Go to the route directly if no confirmation
        }
        //    alert(isDialogOpen);
    };



    const CompanyTo = form.values.JobOffer?.Company;

  return (
    <Stack maw={1000} m="auto">



        <CompanyAddedInfoDialog isOpen={info_opened} params={ifparams}></CompanyAddedInfoDialog>
        {/* Confirmation Dialog */}
        <RequiredCompanyConfirmDialog params={
            params
        }
                                      isOpen={isDialogOpen}/>

      <Title>{t(title)}</Title>
      <FormSection title="job_offer">
        <JobOfferCard jobOffer={form.values.JobOffer} />
      </FormSection>
      <FormSection title="company">
        <CompanyCard company={CompanyTo} />
      </FormSection>
      <FormSection title="add_your_employees">
        <SelectEmployeeTable {...form.getInputProps("employeeId")} />
      </FormSection>
      <FormSection title="your_offer">
        <NumberInput
          required
          label={t("hourly_cost")}
          placeholder="n $"
          {...form.getInputProps("price")}
        />
        <TextInput
          label={t("min_duration_days")}
          placeholder="n days"
          {...form.getInputProps("durationMin")}
        />
        <TextInput
          label={t("max_duration_days")}
          placeholder="n days"
          {...form.getInputProps("durationMax")}
        />
      </FormSection>

      <FormSection title="add_cover_letter">
        <DescriptionInput
          required
          label={t("description")}
          placeholder={t("cover_letter_ph")}
          {...form.getInputProps("desc")}
        />
      </FormSection>

      {/* <FormSection title="set_status">
        <Select
          label={t("status")}
          placeholder={t("status_ph")}
          searchable
          data={["open", "draft"]}
          {...form.getInputProps("status")}
        />
      </FormSection> */}

      <ActionsCheckCompany actions={formActions} isReady={form.isValid()} />
    </Stack>
  );
}
