import {Flex, Paper} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import useIsMobile from "src/hooks/useIsMobile";
import Config from "../../classes/application/Config";
import Application from "../../classes/Application";

interface Props {
    to?: string;
    children?: React.ReactNode;
    style?: React.CSSProperties; // Add the style prop here
}

const Card = ({children, to, style}: Props) => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const w = isMobile ? "100%" : 430;
    const mx = isMobile ? 0 : "xs";
    const onClick = () => {
        const cfg = Config.getInstance();
        const isPublic = cfg.isCurrentPublicUrl();

        let app = Application.getInstance();

        let url = `/app/view/${to}`;
        if (isPublic && !app.isAuth()) {
            url = `/public/view/${to}`;
        }

        if (to) {
            navigate(url);
        }
    };

    return (
        <Paper
            shadow="md"
            p="md"
            mx={mx}
            my="xs"
            radius="md"
            withBorder
            maw={w}
            miw={w}
            onClick={onClick}
            style={style} // Pass the style prop to Paper
        >
            <Flex direction="column" gap="sm">
                {children}
            </Flex>
        </Paper>
    );
};

export default Card;
