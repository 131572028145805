import Actions from "src/components/Actions";
import Loader from "src/components/Loader";
import ProposalTable from "src/components/tables/ProposalTable";
import {useGET} from "src/hooks/useApi";
import Proposal from "src/types/Proposal.type";
import Config from "../../../classes/application/Config";
import Application from "../../../classes/Application";

const ListProposalReceived = () => {
    let {data, isLoading, error} = useGET<Proposal[]>(
        "account/company/proposals_received"
    );

    const cfg = Config.getInstance();
    let app = Application.getInstance();
    if (cfg.hasProgressiveRegister() && !app.hasCompany()) {
        data=[];
        return (<>
                <Actions title="proposals_sent"
                         infoboxCode={"infobox_job_offers_sent"}
                />

                <ProposalTable data={data} />
            </>
        );

    }
    return (
        <Loader isLoading={isLoading} error={error} hasData={!!data}>
            <Actions
                title="proposals_received"
                infoboxCode={"infobox_job_offers_received"}
            />
            <ProposalTable mode={"received"} data={data}/>
        </Loader>
    );
};

export default ListProposalReceived;
