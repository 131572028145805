import React, { useEffect, useState } from 'react';
import { Button, Container, Loader, Center } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useMantineTheme } from '@mantine/core';
import { useNavigate } from "react-router-dom";

import SheetComponent from "../SheetComponent";
import Config from "../../../classes/application/Config";
import { InterviewService } from "../../../classes/interview/service/InterviewService";
import InterviewUserProfileModel from "../../../classes/interview/InterviewUserProfileModel";

const InterviewFinishComponent: React.FC = () => {
    const { t } = useTranslation();
    const theme = useMantineTheme();
    const navigate = useNavigate();

    const isMobile = Config.getInstance().isMobileDevice();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const storeInterview = async () => {
            try {
                const srv = await InterviewService.newInstance();
                const intv = InterviewUserProfileModel.getInstance();


                await srv.storeInterview(intv);
            } catch (error) {
                console.error("Error storing interview:", error);
            } finally {
                setLoading(false);
            }
        };

        storeInterview();
    }, []);

    const handleNextSteps = () => {
        navigate('/app/dashboard');
    };

    // Show a loading spinner while waiting
    if (loading) {
        return (
            <Center style={{ height: '100vh' }}>
                <Loader size="xl" color={theme.primaryColor} />
            </Center>
        );
    }

    let backto="";
    let intv=InterviewUserProfileModel.getInstance();
    if (intv.get_isEmployee()) {
        backto="/app/interview/vat";
    }

 //   alert(intv.get_isEmployee());

    return (
        <SheetComponent backto={backto} title="">
            <Container
                style={{
                    textAlign: 'center',
                    width: "100vw",
                    maxWidth: "100%",
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                    backgroundColor: "#ffffff",
                    alignItems: 'center',
                    borderRadius: "15px",
                    fontSize: "140%",
                    padding: '20px'
                }}
            >
                <h2>{t('interview_thank_you')}</h2>
                <p>{t('interview_your_interview_has_been_successfully_completed')}</p>
                <p>{t('interview_can_use')}</p>

                <div style={{ marginTop: '20px' }}>
                    <Button
                        onClick={handleNextSteps}
                        style={{
                            padding: '15px 30px',
                            fontSize: "120%",
                            backgroundColor: theme.colors[theme.primaryColor][6],
                            color: 'white',
                            borderRadius: '10px',
                            height: "100px",
                        }}
                    >
                        {t('go_to_dashboard')}
                    </Button>
                </div>
            </Container>
        </SheetComponent>
    );
};

export default InterviewFinishComponent;
