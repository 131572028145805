import { Box, Flex, Group, Skeleton, Stack, Text, Title } from "@mantine/core";
import CountryIcon from "../badges/CountryIcon";
import Company from "src/types/Company.type";
import { IconBuildingFactory2 } from "@tabler/icons-react";
import Avatar from "../Avatar";
import CompanyStatus from "../CompanyStatus";
import Description from "../Description";
import { useTranslation } from "react-i18next";
import useIsMobile from "src/hooks/useIsMobile";
import { useNavigate } from "react-router-dom";

interface Props {
  company?: Partial<Company>;
}

const CompanySection = ({ company }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  if (!company) {
    return null;
  }

  const handleClick = () => {
    if(!company.name) {
      return;
    }
    navigate(`/app/view/company/${company.id}`);
  };

  return (
      <Flex
          my="md"
          gap="sm"
          wrap="wrap"
          direction={isMobile ? "column" : "row"}
          ta={isMobile ? "center" : "left"}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
      >
        <Box mx={isMobile ? "auto" : 0}>
          <Avatar size={200} src={company.profilePic}>
            <IconBuildingFactory2 size={90} />
          </Avatar>
        </Box>

        <Stack maw={isMobile ? "95%" : "66%"}>
          {company.name ? (
              <Title tt="capitalize">{company.name}</Title>
          ) : (
              <Skeleton w={300} h={26} />
          )}
          <CompanyStatus company={company as Company} />
          <Group justify={isMobile ? "center" : "normal"}>
            {company.loc_nation && <CountryIcon id={company.loc_nation} />}
            <Text size="small" tt="capitalize">
              {company.size} {company.type}
            </Text>
          </Group>
          {company.desc ? (
              <>
                <Description text={company.desc} />
              </>
          ) : (
              <Text c="grey" bg="#eee" p="xs">
                {t("details_hidden")}
              </Text>
          )}
        </Stack>

        {/* rating */}
        {/* <CompanyRating
        total={company.score_total}
        reliability={company.score_reliability}
        skill={company.score_skills}
        timelines={company.score_timelines}
      /> */}
      </Flex>
  );
};

export default CompanySection;
