import _size from "lodash/size";
import _get from "lodash/get";
import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";
import _capitalize from "lodash/capitalize";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import { Flex, Paper, Table, Text, Title } from "@mantine/core";
import RowActions from "./RowActions";
import { ComponentType, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TableRow } from "src/types/TableRow.type";
import useIsMobile from "src/hooks/useIsMobile";
import GenericCard from "../cards/GenericCard";
import { DateTag } from "../badges/DateTag";

function SimpleTable<T>({
  Row,
  rows,
  cols,
  section,
  readOnly = false,
  col_titles
}: {
  section: string;
  Row: ComponentType<TableRow<T>>;
  rows: (T & { id: string })[];
  cols: string[];
  readOnly?: boolean;
  col_titles?: string[];
  mode?: string;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const sortedRows = useMemo(
    () => _reverse(_sortBy(rows, "meta_created_at")),
    [rows]
  );

  let c_titles:string[]=[]

  if(!col_titles) {
    c_titles = cols;
  }
  else {
    c_titles = col_titles;
  }
  console.log(col_titles);
  if (isMobile) {
    if (_isEmpty(rows)) {
      return (
        <Flex direction="column">
          <Title order={2} ta="center">
            {t("no_data_available")}
          </Title>
        </Flex>
      );
    }
    return (
      <Flex direction="column">
        {_map(rows, (data, index) => (
          <GenericCard key={index} data={data} type={section} />
        ))}
      </Flex>
    );
  }

  return (
    <Paper p={0} radius="lg" miw={900} mx="auto">
      <Table horizontalSpacing="xl" verticalSpacing="lg" highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            {_map(cols, (c, index) => (
              <Table.Th key={c + index}>
                <Text c="primary" fw={800}>

                  {_capitalize(t(c_titles[index]))}
                </Text>
              </Table.Th>
            ))}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {_isEmpty(rows) && (
            <Table.Tr>
              <Table.Td colSpan={_size(cols)} ta="center">
                {t("no_data_available")}
              </Table.Td>
            </Table.Tr>
          )}
          {_map(sortedRows, (row, index) => {
            const createdAt = _get(row, "meta_created_at") as string;

            return (
              <Table.Tr key={row.id + index} style={{ cursor: "pointer" }}>
                <Row
                  item={row}
                  onClick={() => navigate(`/app/view/${section}/${row.id}`)}
                />
                <Table.Td>

                  {createdAt && <DateTag date={createdAt} withTime naked />}
                </Table.Td>
                {!readOnly && (
                  <Table.Td>
                    <RowActions
                      section={section}
                      id={row.id}
                      readOnly={readOnly}
                    />
                  </Table.Td>
                )}
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </Paper>
  );
}

export default SimpleTable;
