import _map from "lodash/map.js";
import { Box, Flex, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import Collapse from "./Collapse";
import NavbarLink from "./NavbarLink";
import { SidebarLink } from "src/types/SidebarLinks";
import Config from "../classes/application/Config";

interface Props {
    links: SidebarLink[];
}

function NavbarLinks({ links }: Props) {
    const { t } = useTranslation();
    let cfg = Config.getInstance();
    let isPublic = cfg.isCurrentPublicUrl();

    return (
        <Box>
            {links.map((link, index) => {
                if (!link.links) {
                    return (
                        <Box className={link.classes} key={link.label}>
                            <NavbarLink key={index + link.label} link={link} />
                        </Box>
                    );
                }
                let cfg = Config.getInstance();
                let isPublic = cfg.isCurrentPublicUrl();
                if(isPublic) {
                      if(cfg.hasFeaturePublicHiddenButtons()) {
                          return <></>
                      }
                }
                return (
                    <Collapse
                        key={index + link.label}
                        isOpen={link.isOpen ?? false}
                        p="xs"
                        c="white"
                        spaced="0"
                        title={
                            <Flex gap="sm" className={link.classes}>
                                <Box ml={2}>{link.icon}</Box>
                                <Text fw={800} size="sm" mt={5} tt="capitalize"
                                      style={{ color: "white", fontWeight: "bold" }}>
                                    {t(link.label)}
                                </Text>
                            </Flex>
                        }
                    >
                        <Box mb="0" mt="-3">
                            {_map(link.links, (l, i) => (
                                <NavbarLink key={i + l.label} link={l} />
                            ))}
                        </Box>
                    </Collapse>
                );
            })}
        </Box>
    );
}

export default NavbarLinks;
