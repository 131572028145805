import {Box} from "@mantine/core";
import MDEditor from "@uiw/react-md-editor";

interface Props {
    text?: string;
    w?: number;
    clamp?: number;
    style?: React.CSSProperties;
}

const Description = ({text, w, style}: Props) => {
    if (!text) return null;
    return (
        <Box w={w} style={style}>
            <MDEditor.Markdown
                style={{
                    background: "transparent",
                    ...style,
                }}
                source={text}
            />
        </Box>
    );
};

export default Description;
