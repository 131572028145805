import { ApiService } from "../ApiService.js";
import { ServiceResponse } from "../ServiceResponse";
import Config from "../../../classes/application/Config";
import {ApiServiceAxios} from "./ApiServiceAxios";
import Application from "../../../classes/Application";
import {ApiServices} from "../ApiServices";

const endpointCallTimestamps = new Map();
async function waitForCondition<T>(
    conditionFn: () => T | Promise<T>,
    interval: number = 100,
    timeout: number = 5000
): Promise<T> {
    const startTime = Date.now();


    return new Promise<T>((resolve, reject) => {
        const checkCondition = async () => {
            const result = await conditionFn();
            if (result) {
                resolve(result);
            } else if (Date.now() - startTime >= timeout) {
                reject(new Error('Timeout waiting for condition'));
            } else {
                setTimeout(checkCondition, interval);
            }
        };

        checkCondition();
    });
}

export class ApiServiceXmlHttp extends ApiService {
    
    constructor(apiUrl: string) {
        super(apiUrl);
    }


    /**
     * Creates a new instance of the class it's called on.
     * @param this - The constructor of the class.
     * @param apiUrl - The API URL.
     * @returns A new instance of the class.
     */
    public static async newInstance<T extends typeof ApiServiceXmlHttp>(
        this: T,
        apiUrl?: string
    ):  Promise<InstanceType<T>> {
        const cfg = Config.getInstance();
        if (!apiUrl) {
            apiUrl = cfg.getApiUrl();
        }
        if (!apiUrl) {
            apiUrl = "";
        }

        let app = Application.getInstance();
        let tok = app.getToken();

        const token = await waitForCondition(() => app.getToken(), 100, 5000);

        let inst= new this(apiUrl) as InstanceType<T>;
        inst.setToken(tok);
        return inst;
    }



    // Getter for token
    public getToken(): string | undefined {

        if(!this._token) {
            this._token=Application.getInstance().getToken();
        }
        return this._token;
    }

    // Setter for token
    public setToken(token: string | undefined): void {
        this._token = token;
    }

    test() {
        console.log("testing");
    }

    /**
     * Reads a stream of strings from an HTTP connection.
     * @param url - The endpoint URL for the HTTP connection.
     * @param options - Optional fetch configuration (e.g., headers, method).
     * @param onRead - A callback function to handle each read chunk.
     * @param onError - A callback function to handle any errors.
     * @param onComplete - A callback function to handle the completion of the stream.
     * @returns A promise that resolves when the stream is fully processed.
     */
    public async readHttpStream(
        url: string,
        options?: RequestInit,
        onRead?: (chunk: string) => void,
        onError?: (error: any) => void,
        onComplete?: () => void
    ): Promise<void> {
        console.log("START FEEEEEEEEEEEE");
        const headers: Record<string, string> = {};
        if (this._token) {
            headers["Authorization"] = `Bearer ${this._token}`;
        }
        const response = await fetch(`${this._apiUrl}${url}`, { ...options, headers });

        if (!response.ok) {
            const error = new Error(`Failed to fetch stream. Status: ${response.status}`);
            if (onError) onError(error);
            throw error;
        }

        const reader = response.body?.getReader();

        if (!reader) {
            const error = new Error("The HTTP response does not contain a readable body.");
            if (onError) onError(error);
            throw error;
        }

        const decoder = new TextDecoder(); // Decodes UTF-8 chunks into strings

        try {
            // Read the stream
            let chunkData = '';
            while (true) {
                const { value, done } = await reader.read();
                if (done) break; // End of the stream

                if (value) {
                    const chunk = decoder.decode(value, { stream: true });
                    chunkData += chunk; // Append the chunk to chunkData

                    // If there's a read callback, pass the chunk to it
                    if (onRead) onRead(chunk);
                }
            }

            // Optionally, process the entire stream data at once
            if (onRead && chunkData) {
                onRead(chunkData);
            }

        } catch (err) {
            console.error("Error while reading HTTP stream:", err);
            if (onError) onError(err); // Handle error using the provided handler
            throw err;
        } finally {
            // Call onComplete when done
            if (onComplete) onComplete();
            reader.releaseLock();
        }
    }

    public async readStringStream(stream: ReadableStream<string>): Promise<void> {
    }
    public post_OLD(endpoint: string, data: any, response?: ServiceResponse): Promise<any> {
        let self = this;
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            let ep = this._apiUrl + "/" + endpoint;
            xhr.open("POST", ep, true);

            xhr.setRequestHeader("Content-Type", "application/json");
            if (this._token) {
                xhr.setRequestHeader("Authorization", `Bearer ${this._token}`);
            }

            let sr = response ?? new ServiceResponse();

            xhr.timeout = 10000; // Set a timeout of 10 seconds

            xhr.onload = () => {
                sr.setStatusCode(xhr.status);
                if (xhr.status >= 200 && xhr.status < 300) {
                    try {
                        sr.setData(JSON.parse(xhr.responseText));
                        resolve(sr);
                    } catch (error) {
                        console.error("JSON parsing error:", error);
                        sr.setStatusCode(500);
                        reject(sr);
                    }
                } else {
                    console.warn(`Request failed with status: ${xhr.status}`);
                    reject(sr);
                }
            };

            xhr.onerror = () => {
                console.error("Network error occurred.");
                sr.setStatusCode(xhr.status || 0); // Status 0 for network errors
                reject(sr);
            };

            xhr.ontimeout = () => {
                console.error("Request timed out.");
                sr.setStatusCode(408); // 408 Request Timeout
                reject(sr);
            };

            try {
                xhr.send(JSON.stringify(data));
            } catch (error) {
                console.error("Request sending error:", error);
                sr.setStatusCode(500);
                reject(sr);
            }
        });
    }


    public get(endpoint: string): Promise<any> {
        let self = this;
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            let ep = this._apiUrl + "/" + endpoint;
            xhr.open("GET", ep, true);

            xhr.setRequestHeader("Content-Type", "application/json");

            if (this._token) {
                xhr.setRequestHeader("Authorization", `Bearer ${this._token}`);
            }

            let sr = new ServiceResponse();

            xhr.onload = () => {
                sr.setStatusCode(xhr.status);
                if (xhr.status === 200) {
                    sr.setData(JSON.parse(xhr.responseText));
                    resolve(sr);
                } else {
                    sr.setStatusCode(400);
                    reject(sr);
                }
            };

            xhr.onerror = (err) => {
                console.error("Request error:", err);
                sr.setStatusCode(400);
                reject(sr);
            };

            xhr.send();
        });
    }

    public async putSafe(endpoint: string, data: any, response?: ServiceResponse): Promise<any> {
        const now = Date.now();
        const lastCall = endpointCallTimestamps.get(endpoint) || 0;

        if (now - lastCall < 3000) {
            return Promise.reject(new Error(`Too many requests to ${endpoint}. Please wait.`));
        }

        endpointCallTimestamps.set(endpoint, now);


        return this.put(endpoint, data, response);
    }

    public put(endpoint: string, data: any, response?: ServiceResponse): Promise<any> {
        let self = this;
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            let ep = this._apiUrl + "/" + endpoint;
            xhr.open("PUT", ep, true);

            xhr.setRequestHeader("Content-Type", "application/json");
            if (this._token) {
                //   alert(this._token);
                xhr.setRequestHeader("Authorization", `Bearer ${this._token}`);
            }

            let sr = new ServiceResponse();
            if (response) {
                sr = response;
            }
            xhr.onload = () => {
                sr.setStatusCode(xhr.status);
                if (xhr.status === 200) {
                    try {
                        sr.setData(JSON.parse(xhr.responseText));

                    } catch (e: unknown) {
                        if (e instanceof Error) {
                        } else {
                        }
                    }
                    resolve(sr);
                } else {
                    sr.setStatusCode(400);
                    reject(sr);
                }
            };

            xhr.onerror = (err) => {
                console.error("Request error:", err);
                sr.setStatusCode(400);
                reject(sr);
            };

            // Send the request with the data in the body
            xhr.send(JSON.stringify(data));
        });
    }

    public post(endpoint: string, data: any, response?: ServiceResponse): Promise<any> {
        let self = this;
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            let ep = this._apiUrl + "/" + endpoint;
            xhr.open("PUT", ep, true);

            xhr.setRequestHeader("Content-Type", "application/json");
            if (this._token) {
                //   alert(this._token);
                xhr.setRequestHeader("Authorization", `Bearer ${this._token}`);
            }

            let sr = new ServiceResponse();
            if (response) {
                sr = response;
            }
            xhr.onload = () => {
                sr.setStatusCode(xhr.status);
                if (xhr.status === 200) {
                    sr.setData(JSON.parse(xhr.responseText));
                    resolve(sr);
                } else {
                    sr.setStatusCode(400);
                    reject(sr);
                }
            };

            xhr.onerror = (err) => {
                console.error("Request error:", err);
                sr.setStatusCode(400);
                reject(sr);
            };

            // Send the request with the data in the body
            xhr.send(JSON.stringify(data));
        });
    }
}
