import Application from "../../classes/Application";
import {UTMHandler} from "./UTMHandler";

export class TrackingSystem {
    private static _instance: TrackingSystem;

    private constructor() {
    }

    public static getInstance(): TrackingSystem {
        if (!TrackingSystem._instance) {
            TrackingSystem._instance = new TrackingSystem();
        }
        return TrackingSystem._instance;
    }

    private waitForGtagReady(callback: () => void) {
        const checkGtagReady = () => {
            // @ts-ignore
            if (window.gtag_ready === 1) {
                callback();
            } else {
                setTimeout(checkGtagReady, 100);  // Retry after 100ms
            }
        };

        checkGtagReady();  // Start checking
    }

    // Method to execute before sending any event
    private onBeforeSendEvent(): void {
        this.prepareUtm();  // Ensure UTM parameters are prepared and saved if needed
    }

    private getUTMParams(): any {
        const utmHandler = this.getUTMHandler();
        return utmHandler.isUTMValid() ? utmHandler.getUTMParams() : {};
    }

    public sendPageView(pageTitle: string, context: string = "A8App") {
        this.onBeforeSendEvent();  // Call onBeforeSendEvent before sending the event

        const app = Application.getInstance();
        const isAuth = app.isAuth();

        let auth: string = "0";
        if (isAuth) {
            auth = "1";
        }

        const utmParams = this.getUTMParams(); // Get UTM params if valid

        this.waitForGtagReady(() => {
            // @ts-ignore
            if (window.gtag) {
                window.gtag('event', 'page_view', {
                    page_title: `(${context}) - ${pageTitle}`,
                    page_location: window.location.href,
                    page_path: window.location.pathname,
                    app_is_auth: auth,
                    event_category: context,
                    category: context,
                    ...utmParams // Include UTM parameters if valid
                });
            } else {
                console.warn('Google Analytics not loaded');
            }
        });
    }

    public sendCustomEvent(eventName: string, context: string = "a8app", eventParams: any = {}) {
        this.onBeforeSendEvent();  // Call onBeforeSendEvent before sending the event

        const app = Application.getInstance();
        const isAuth = app.isAuth();

        let auth: string = "0";
        if (isAuth) {
            auth = "1";
        }
        eventName = context + "_" + eventName;

        const utmParams = this.getUTMParams(); // Get UTM params if valid

        const finalParams = {
            ...eventParams,
            app_is_auth: auth,
            event_label: context,
            event_category: context,
            category: context,
            ...utmParams // Include UTM parameters if valid
        };

        this.waitForGtagReady(() => {
            // @ts-ignore
            if (window.gtag) {
                window.gtag('event', eventName, finalParams);
            } else {
                console.warn('Google Analytics not loaded');
            }
        });
    }

    public sendClick(clickName: string, context: string = "a8app", clickParams: any = {}) {
        this.onBeforeSendEvent();  // Call onBeforeSendEvent before sending the event

        const app = Application.getInstance();
        const isAuth = app.isAuth();

        let auth: string = "0";
        if (isAuth) {
            auth = "1";
        }
        clickName = context + "_clicked_" + clickName;

        const utmParams = this.getUTMParams(); // Get UTM params if valid

        const finalParams = {
            ...clickParams,
            app_is_auth: auth,
            event_label: context,
            event_category: 'click',
            category: 'click',
            ...utmParams 
        };

        this.waitForGtagReady(() => {
            // @ts-ignore
            if (window.gtag) {
                window.gtag('event', clickName, finalParams);
            } else {
                console.warn('Google Analytics not loaded');
            }
        });
    }

    public getUTMHandler(): UTMHandler {
        return UTMHandler.getInstance();
    }

    public prepareUtm(): void {
        const utmHandler = this.getUTMHandler();
        const utmParams = utmHandler.getUTMParams();
        const currentCampaign = utmParams.utm_campaign;

        if (!currentCampaign || !utmHandler.isUTMValid()) {
            utmHandler.saveUTMParams();
        }
    }
}

export default TrackingSystem;
