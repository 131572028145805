import { Table, Text } from "@mantine/core";

const MatchesEmployeesDetail = ({ item }: any) => {
    if (!item || !item.itemData || !item.itemData.employees) return null;

    const employees = item.itemData.employees

    return (
        <Table>
            <thead>
            <tr>
                <th>Employee ID</th>
                <th>Name</th>
                <th>Surname</th>

                <th>Desc</th>
                <th>Hourly rate</th>
                <th>Nationality</th>
           </tr>
            </thead>
            <tbody>
            {employees.length > 0 ? (
                employees.map((employee: any) => (
                    <tr key={employee.id}>
                        <td>{employee.id}</td>
                        <td>{employee.name}</td>
                        <td>{employee.surname}</td>
                        <td>{employee.desc}</td>
                        <td>{employee.hourlyRate}</td>
                        <td>{employee.nationality}</td>

                    </tr>
                ))
            ) : (
                <tr>
                    <td colSpan={4}>No employees available</td>
                </tr>
            )}
            </tbody>
        </Table>
    );
};

export default MatchesEmployeesDetail;
