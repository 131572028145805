import { ActionIcon, Button, Stack, Tooltip } from "@mantine/core";
import { IconBrandWhatsapp, IconSTurnRight } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useI81nTour } from "src/hooks/useI18nTour";
import useIsMobile from "src/hooks/useIsMobile";
import Config from "../classes/application/Config";
import LayoutHandler from "../classes/ui/LayoutHandler";
interface Props {
  small?: boolean;
}
const whatsapp = import.meta.env.VITE_SUPPORT_WHATSAPP ?? "";

export default function SupportLinks({ small }: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { setIsOpen } = useI81nTour();


  const tourLink = {
    onClick: () => setIsOpen(true),
    url: `#`,
    label: "tour",
    color: "primary",
    Icon: IconSTurnRight,
    classes: "tour_tour",
  };

  let lh = LayoutHandler.getInstance();
  if(!lh.shouldShowSupportLinks()) {
    return <></>;
  }
  const whatsappLink = {
    onClick: () => window?.open(`https://wa.me/${whatsapp}`, "_blank")?.focus(),
    label: "open_ticket_whatsapp",
    color: "#00971F",
    Icon: IconBrandWhatsapp,
    classes: "tour_support",
  };
  let cfg=Config.getInstance();
  if(!cfg.isCurrentPublicUrl()) {

  }

  // Initialize links array
  let links = [whatsappLink]; // Start with whatsappLink only

// Add tourLink if it's not a public area
  if (!cfg.isCurrentPublicUrl()) {
    links = [tourLink, ...links]; // Add tourLink at the start
  }
  const size = isMobile ? "24px" : "32px";

  if (small) {
    return (
      <Stack
        align="center"
        style={{ position: "fixed", right: 10, bottom: isMobile ? 10 : 80 }}
      >
        {links.map((l) => (
          <Tooltip key={l.label} label={t(l.label)}>
            <ActionIcon
              color={l.color}
              aria-label={l.label}
              radius="xl"
              p={isMobile ? "25" : "xl"}
              onClick={l.onClick}
            >
              <l.Icon
                style={{ width: size, height: size }}
                stroke={2.5}
                className={l.classes}
              />
            </ActionIcon>
          </Tooltip>
        ))}
      </Stack>
    );
  }

  return (
    <Stack align="center">
      {links.map((l) => (
        <React.Fragment key={l.label}>
          <Button
            px="xl"
            leftSection={<l.Icon />}
            bg={l.color}
            onClick={l.onClick}
            fullWidth={isMobile}
          >
            {t(l.label)}
          </Button>
        </React.Fragment>
      ))}
    </Stack>
  );
}
