import {Stack, Text} from "@mantine/core";
import {InfoList} from "src/components/InfoList";
import Application from "src/types/Application.type";
import {useTranslation} from "react-i18next";
import ApplicationStatusPill from "../ApplicationStatusPill";
import {EmployeeList} from "../badges/EmployeeList";

interface Props {
    application: Application;
}

const ApplicationSection = ({application}: Props) => {
    const {durationMin, durationMax, price, Employees} = application;
    const {t} = useTranslation();

    return (
        <Stack gap="sm">
            <InfoList info="status" wide>
                <ApplicationStatusPill status={application.status}/>
            </InfoList>
            <InfoList info="min_duration" wide>
                <Text size="sm">
                    {durationMin} {t("days")}
                </Text>
            </InfoList>
            <InfoList info="max_duration" wide>
                <Text size="sm">
                    {durationMax} {t("days")}
                </Text>
            </InfoList>
            <InfoList info="exp_cost" wide>
                <Text size="sm">
                    {price} {t("euro_hour")}
                </Text>
            </InfoList>
            <InfoList info="employees" wide>
                <EmployeeList data={Employees}/>
            </InfoList>
        </Stack>
    );
};

export default ApplicationSection;
