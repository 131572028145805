import React, {useState} from 'react';
import {Button, Container} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import SheetComponent from '../SheetComponent';
import './VatQuestionComponent.css';
import InterviewUserProfileModel from "../../../classes/interview/InterviewUserProfileModel";
import {useNavigate} from "react-router-dom";
import {useMantineTheme} from '@mantine/core';
import Config from "../../../classes/application/Config";

const VatQuestionComponent: React.FC = () => {
    const {t} = useTranslation();
    const [hasVatNumber, setHasVatNumber] = useState<boolean | null>(null);
    const navigate = useNavigate();
    const [hoveredButton, setHoveredButton] = useState<string | null>(null);

    const setHoveredButtonWithLog = (option: string | null) => {
        console.log(`Hovered button changed to: ${option}`);
        setHoveredButton(option);
    };

    const handleAnswer = (answer: string) => {
        let intv = InterviewUserProfileModel.getInstance();
        if (answer === "company") {
            intv.set_hasVat(true);
            intv.set_isCompany(true);
            intv.set_isFreelancer(false);
            intv.set_isEmployee(false);
            setHasVatNumber(true);
            intv.storeLocal();
            navigate("/app/interview/company/interests");
            return;
        }
        if (answer === "freelance") {
            intv.set_hasVat(true);
            intv.set_isCompany(false);
            intv.set_isFreelancer(true);
            intv.set_isEmployee(false);
            setHasVatNumber(true);
            intv.storeLocal();
            navigate("/app/interview/prof/employees");
            return;
        }
        intv.set_isCompany(false);
        intv.set_isFreelancer(false);
        intv.set_isEmployee(true);
        intv.set_hasEmployees(false);
        intv.set_considerOpeningVat(true);
        intv.storeLocal();
        setHasVatNumber(false);
        navigate("/app/interview/finished");
    };

    let cfg = Config.getInstance();
    let isMobile = cfg.isMobileDevice();
    let minWidth = '400px';
    if(isMobile) {
        minWidth='100%'
    }
    const minHeight = '110px';
    const buttonPadding = '1px';
    let maxWidthMobile = '300px';
    const maxWidthDesktop = '30%';

    const getBackgroundColor = (option: string): string => {
        return hoveredButton === option ? '#dddddd' : '#efefef';
    };

    const renderButton = (option: string, label: string) => (
        <Button
            onClick={() => handleAnswer(option)}
            className="button yes-button"
            size="md"
            onMouseEnter={() => setHoveredButtonWithLog(option)}
            onMouseLeave={() => setHoveredButtonWithLog(null)}
            style={{

                fontSize:'110%',
                maxWidth: isMobile ? maxWidthMobile : maxWidthDesktop,
                minHeight: minHeight,
                maxHeight: '150px',
                minWidth: minWidth,
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                textAlign: 'center',
                padding: buttonPadding,
                lineHeight: '1.6',
                flex: '1 1 auto',
                backgroundColor: getBackgroundColor(option),
                borderColor: 'white',
                borderRadius: '10px',
                marginBottom: '10px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
                color: "black",
            }}
        >

            <div style={{
                textTransform:"none"
            }}>

            </div>
            {t(label)}
        </Button>
    );
    let wdt='';

    if(isMobile) {
        wdt='100%';
    }
    let wdtcont='80%';

    if(isMobile) {
        wdtcont='100%';
    }
    return (
        <SheetComponent title="" backto="">
            <Container
                style={{
                    textAlign: 'center',
                    width: wdtcont,
                    maxWidth: "100%",
                    display: 'flex',
                    flexDirection: 'column',
             //       border:'1px solid red',
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                    backgroundColor: "#dddddd",
                    alignItems: 'center',
                    borderRadius: "15px",
                    fontSize: "140%",
                    padding: '20px'
                }}
            >
                <h2>{t('main_do_you_have_vat')}</h2>
                {isMobile ? (
                    <div style={{width:'100%',display: 'flex', justifyContent: 'center', gap: '20px', flexDirection: 'column'}}>
                        {renderButton("company", 'main_yes_company')}
                        {renderButton("freelance", 'main_yes_professional')}
                    </div>
                ) : (
                    <div style={{display: 'flex', justifyContent: 'center', gap: '20px', flexDirection: 'row'}}>
                        {renderButton("company", 'main_yes_company')}
                        {renderButton("freelance", 'main_yes_professional')}
                    </div>
                )}

                {isMobile ? (
                    <>
                    </>
                ) : (
                    <>
                    </>
                )}
                <div style={{width:wdt,display: 'flex', justifyContent: 'center', gap: '20px', flexDirection: 'column'}}>
                <br/>
                    <Button
                        onClick={() => handleAnswer("no")}
                        size="md"
                        className="button yes-button"
                        onMouseEnter={() => setHoveredButtonWithLog("no")}
                        onMouseLeave={() => setHoveredButtonWithLog(null)}
                        style={{
                            maxWidth: isMobile ? maxWidthMobile : maxWidthDesktop,
                            minHeight: minHeight,
                            maxHeight: '150px',
                            minWidth: minWidth,
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                            textAlign: 'center',
                            padding: buttonPadding,
                            lineHeight: '1.6',
                            flex: '1 1 auto',
                            backgroundColor: getBackgroundColor('no'),
                            borderColor: 'white',
                            borderRadius: '10px',
                            marginBottom: '10px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
                            color: "black",
                            fontSize:'110%'
                        }}
                    >
                        {t('main_no_i_want_to_be_employee')}
                    </Button>
                </div>

            </Container>
        </SheetComponent>
    );
};

export default VatQuestionComponent;
