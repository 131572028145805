import React, {useState} from 'react';
import {Button, Container} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import SheetComponent from "../../SheetComponent";
import Config from "../../../../classes/application/Config";
import {useMantineTheme} from '@mantine/core';

import InterviewUserProfileModel from "../../../../classes/interview/InterviewUserProfileModel";
import {useNavigate} from "react-router-dom";

const ConsiderBecomingEmployeeQuestionComponent: React.FC = () => {
    const {t} = useTranslation();
//    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    let intv = InterviewUserProfileModel.getInstance();

    const considers_employee = intv.get_considerToBeEmployed();
    const [selectedOption, setSelectedOption] = useState<string | null>(
        considers_employee === true ? "yes" : considers_employee === false ? "no" : null
    );

    const [hoveredButton, setHoveredButton] = useState<string | null>(null);

    const minWidth = '100px';
    const minHeight = '100px';
    const buttonPadding = '30px';
    const maxWidthMobile = '95%';
    const maxWidthDesktop = '30%';

    // Get Mantine theme for primary color
    const theme = useMantineTheme();
    const primaryColor = theme.colors[theme.primaryColor][6];
    const navigate = useNavigate();

    const getBackgroundColor = (option: string, selectedOption: string | null): string => {

        if (hoveredButton === option) {

            if(selectedOption==option) {
                return "";
            }
            return '#dddddd';
        } else if (selectedOption === option) {
            return primaryColor;
        } else {
            return '#efefef';
        }
    };

  //  let considers_employee = intv.get_considerToBeEmployed();

    intv.setLastPage('/app/interview/prof/consideremployee');

    const handleSelectOption = (option: string) => {
        setSelectedOption(option);

        if (option === "yes") {
            intv.set_considerToBeEmployed(true);
            intv.storeLocal();
            navigate("/app/interview/insvat")

            return;
        }
        if (option === "no") {
            intv.set_considerToBeEmployed(false);
            intv.storeLocal();
            navigate("/app/interview/insvat")
            return;
        }
    };

    const isMobile = Config.getInstance().isMobileDevice();

    return (
        <SheetComponent backto="/app/interview/vat" title="">
            <Container
                style={{
                    textAlign: 'center',
                    width: "100vw",
                    maxWidth: "100%",
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                    backgroundColor: "#dddddd",
                    alignItems: 'center',
                    borderRadius: "15px",
                    fontSize: "140%",

                    padding: '20px'
                }}
            >
                <h2>{t('freelancer_consider_become_employee')}</h2>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '10px',
                        width: '100%',
                        flexDirection: isMobile ? 'column' : 'row',
                        textAlign: 'center',
                        alignItems: 'center',
                    }}
                >
                    {["yes", "no"].map(option => (
                        <Button
                            key={option}
                            onClick={() => handleSelectOption(option)}
                            onMouseEnter={() => setHoveredButton(option)}
                            onMouseLeave={() => setHoveredButton(null)}
                            style={{
                                width: isMobile ? maxWidthMobile : maxWidthDesktop,
                                maxWidth: isMobile ? maxWidthMobile : maxWidthDesktop,
                                minHeight: minHeight,
                                maxHeight: '150px',
                                minWidth: minWidth,
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                                textAlign: 'center',
                                padding: buttonPadding,
                                lineHeight: '1.6',
                                flex: '1 1 auto',
                                borderColor: 'white',
                                borderRadius: '10px',
                                marginBottom: '10px',
                                fontSize: "140%",

                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
                                backgroundColor: getBackgroundColor(option, selectedOption),
                                color: 'black',
                            }}
                            variant={selectedOption === option ? 'filled' : 'outline'}
                        >
                            {t(`${option}`)}
                        </Button>
                    ))}
                </div>
            </Container>
        </SheetComponent>
    );
};

export default ConsiderBecomingEmployeeQuestionComponent;
