import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import Actions from "src/components/Actions";
import Loader from "src/components/Loader";
import MatchesTable from "src/components/tables/MatchesTable";
import {useGET} from "src/hooks/useApi";
import Matches from "src/types/Matches.type";
import {useMemo} from "react";

const ListMatches = () => {
   // const {data, isLoading, error} = useGET<Matches>("account/company/matches");
    const {data, isLoading, error} = useGET<any>("account/company/matches");

//    const listFrom = _map(data?.from, "CompanyFrom");

    const listFrom = _map(data?.from, (item) => ({
        CompanyFrom: item.CompanyFrom, // Extract "CompanyFrom"
        itemData: item, // Include full data item
    }));

//    const listTo = _map(data?.to, "CompanyTo");

    const listTo = _map(data?.to, (item) => ({
        CompanyTo: item.CompanyTo, // Extract "CompanyTo"
        itemData: item, // Include full data item
    }));

//    alert(JSON.stringify(data.application));

 //   alert(JSON.stringify(data.application));
    const {list, isEmpty} = useMemo(
        () => ({
            list: [...listFrom, ...listTo],
            isEmpty: false
            //   isEmpty: _isEmpty(listFrom) && _isEmpty(listTo),

        }),
        [listFrom, listTo]
    );
    // alert(JSON.stringify(list));

    console.log(list);
    return (
        <Loader isLoading={isLoading} error={error} hasData={!isEmpty}>
            <Actions
                title="matches_confirmed"
                infoboxCode={"infobox_matches"}

            />


            <MatchesTable data={list}/>
        </Loader>
    );
};

export default ListMatches;
