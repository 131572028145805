import _size from "lodash/size";
import _get from "lodash/get";
import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";
import _capitalize from "lodash/capitalize";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import { Flex, Paper, Table, Text, Title } from "@mantine/core";
import RowActions from "./RowActions";
import { ComponentType, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TableRow } from "src/types/TableRow.type";
import useIsMobile from "src/hooks/useIsMobile";
import GenericCard from "../cards/GenericCard";
import { DateTag } from "../badges/DateTag";
import MatchesTableRow from "./rows/MatchesTable.Row";
import { tcap } from "../../lib/I18n/TCapitalize";

function SimpleTableMatches<T>({
                                   Row,
                                   rows,
                                   cols,
                                   section,
                                   readOnly = false,
                                   col_titles,
                               }: {
    section: string;
    Row: ComponentType<TableRow<T>>;
    rows: (T & { id: string })[];
    cols: string[];
    readOnly?: boolean;
    col_titles?: string[];
    mode?: string;
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    let showRowJson: boolean = false;
    let c_titles: string[] = [];

    if (!col_titles) {
        c_titles = cols;
    } else {
        c_titles = col_titles;
    }

    if (false && isMobile) {
        if (_isEmpty(rows)) {
            return (
                <Flex direction="column">
                    <Title order={2} ta="center">
                        {t("no_data_available")}
                    </Title>
                </Flex>
            );
        }
        return (
            <Flex direction="column">
                {_map(rows, (data, index) => (
                    <GenericCard key={index} data={data} type={section} />
                ))}
            </Flex>
        );
    }

    return (
        <Paper p={0} radius="lg" miw={900} mx="auto">
            <Table horizontalSpacing="xl" verticalSpacing="lg" >
                <Table.Thead>
                    <Table.Tr style={{
                        display:"none"
                    }}>
                        <Table.Th>
                            <Text c="primary" fw={800}>
                                {""}
                            </Text>
                        </Table.Th>

                        <Table.Th>
                            <Text c="primary" fw={800}>
                                {t("company").charAt(0).toUpperCase() + t("company").slice(1)}
                            </Text>
                        </Table.Th>

                        <Table.Th>
                            <Text c="primary" fw={800}>
                                {tcap("vat")}
                            </Text>
                        </Table.Th>

                        <Table.Th>
                            <Text c="primary" fw={800}>
                                {tcap("size")}
                            </Text>
                        </Table.Th>

                        <Table.Th>
                            <Text c="primary" fw={800}>
                                {tcap("contacts")}
                            </Text>
                        </Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {_isEmpty(rows) && (
                        <Table.Tr>
                            <Table.Td colSpan={_size(cols)} ta="center">
                                {t("no_data_available")}
                            </Table.Td>
                        </Table.Tr>
                    )}
                    {_map(rows, (row, index) => (
                        <>

                            <Table.Tr>
                                <Table.Th>
                                    <Text c="primary" fw={800}>
                                        {""}
                                    </Text>
                                </Table.Th>

                                <Table.Th>
                                    <Text c="primary" fw={800}>
                                        {t("company").charAt(0).toUpperCase() + t("company").slice(1)}
                                    </Text>
                                </Table.Th>

                                <Table.Th>
                                    <Text c="primary" fw={800}>
                                        {tcap("vat")}
                                    </Text>
                                </Table.Th>

                                <Table.Th>
                                    <Text c="primary" fw={800}>
                                        {tcap("size")}
                                    </Text>
                                </Table.Th>

                                <Table.Th>
                                    <Text c="primary" fw={800}>
                                        {tcap("contacts")}
                                    </Text>
                                </Table.Th>
                            </Table.Tr>


                            {/* MatchesRow Item */}
                            <MatchesTableRow key={row.id || index} item={row} onClick={() => {}} />

                            {/* Separator */}
                            {index < rows.length - 1 && (
                                <Table.Tr>
                                    <Table.Td colSpan={200} style={{ borderTop: "1px solid #e0e0e0", backgroundColor:"#eeeeee" }}></Table.Td>
                                </Table.Tr>
                            )}

                        </>
                    ))}

                    {/* New Row with JSON Data */}
                    {showRowJson && (
                        <Table.Tr>
                            <Table.Td colSpan={10}>
                                <pre>{JSON.stringify(rows, null, 2)}</pre>
                            </Table.Td>
                        </Table.Tr>
                    )}
                </Table.Tbody>
            </Table>
        </Paper>
    );
}

export default SimpleTableMatches;
