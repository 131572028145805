// @ts-nocheck
import {useEffect} from "react";
import Config from "../classes/application/Config";

declare global {
    interface Window {
        gtag: (...args: any[]) => void;
        gtag_added?: number;
        gtag_ready?: number;
        dataLayer?: any[];
        helmet_in_page?: boolean;
    }
}

const GA4Tracker = ({trackingId = ""}) => {
    useEffect(() => {

        if (window.gtag_added) return;

        const interval = setInterval(() => {
            if (window.helmet_in_page) {
                clearInterval(interval);
                initializeGtag();
            }
        }, 1000); // Check every second

        const initializeGtag = () => {

            if (window.gtag_added) return;
            window.gtag_added = 1;

            const script = document.createElement("script");
            script.async = true;
            script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
            document.head.appendChild(script);

            script.onload = () => {
                let gtmScripts = document.querySelectorAll('script[src*="gtm.js"], script[src*="googletagmanager.com"]');
                gtmScripts.forEach((s) => s.remove());

                window.dataLayer = window.dataLayer || [];
                window.gtag = function () {
                    window.dataLayer!.push(arguments);
                };

                window.gtag("js", new Date());

                const cfg = Config.getInstance();
                let ga4MeasurementId = cfg.isEnvDevelopment() ? "G-7XLMDC557W" : "G-52Q1X7ME7M";

                console.log(cfg.isEnvDevelopment() ? "GA4DEV" : "GA4PROD");
                if (!cfg.isEnvProduction() && !cfg.isEnvDevelopment()) {
                    ga4MeasurementId = "";
                }

                window.gtag("config", ga4MeasurementId, {
                    debug_mode: true,
                    send_page_view: false,
                });

                window.gtag_ready = 1;
            };
        };

        return () => clearInterval(interval);
    }, [trackingId]);

    return null;
};

export default GA4Tracker;
