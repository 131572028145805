import _noop from "lodash/noop";
import _map from "lodash/map";
import _groupBy from "lodash/groupBy";


import {useGET} from "src/hooks/useApi";
import _get from "lodash/get";

import MultiInputMatrix from "../../../components/MultiInputMatrix";
import {InputMatrixOption} from "src/types/InputMatrixOption";
import {MultiSelect, Stack, Text, Title} from "@mantine/core";
import {Fragment, ReactNode, useMemo} from "react";
import {useTranslation} from "react-i18next";
import MultiInputMatrixNew from "../../../components/MultiInputMatrixNew";
import EmployeeSkillsGroupComponent from "./EmployeeSkillsGroupComponent";
import Config from "../../../classes/application/Config";
import {usePublicGET} from "../../../hooks/useApiPublic";
import {PaginationResponse} from "../../../types/Pagination.Type";
import Employee from "../../../types/Employee.type";
import EmployeeHandler from "../Handler/EmployeeHandler";
import "./EmployeeSkillsComponent.css"

interface Props {
    values: string[];
    setValues?: (a: string[]) => void;
    errors?: ReactNode | null;
}

interface Skill {
    type: string;
    title: string;
    availableValues: InputMatrixOption[];
}

const EmployeeSkillsComponent = (props: Props) => {
    const {setValues, values, errors} = props;

    let cfg = Config.getInstance();
    let isPublic = cfg.isCurrentPublicUrl();

    let data: any = null;

    if (isPublic) {
        ({data} = usePublicGET<Skill[]>(`static/skills`));
    } else {
        ({data} = useGET<Skill[]>(`static/skills`));

    }
    const {t} = useTranslation();

    const groups = useMemo(() => _groupBy(data, "type"), [data]);

    const totalSkills = useMemo(() => {
        return data?.reduce((count: number, skill: any) => count + (skill.availableValues?.length || 0), 0) || 0;
    }, [data]);

    const getTotalCount = (availableValueGroups: any[], sizes: number[]): number => {
        let totalCount = 0;

        availableValueGroups.forEach((_, index) => {
            const count = sizes[index] || 0; // Get the count from `sizes` or default to 0
            totalCount += count; // Add it to the total count
        });

        return totalCount; // Return the total count
    };

    let eh = EmployeeHandler.getInstance();
    /*

    const availableGroups = [];
    for (const id in groups) {
        if (groups.hasOwnProperty(id)) {
            const group = groups[id];
            availableGroups.push(group); // Add the group to the availableGroups array

            availableGroups.forEach((item, index) => {
                const count = _get(sizes, [index], 0); // Retrieve the count
                eh.addSkillCount(count); // Update the skill count
                console.log(count);
                console.log("totalx: " + eh.getSkillCount());
            });

        }
    }
  */

    return (
        <div className="flex justify-center items-center w-full">
            <div className="w-full max-w-md">
                {errors && (
                    <Text size="xs" c="red" className="text-center">
                        {errors}
                    </Text>
                )}
                <Stack
                    style={{
                        border: errors ? "1px solid red" : "none",
                        alignItems: 'center'
                    }}
                >
                    {_map(groups, (g, id) => (
                        <Fragment key={id}>
                            <Title order={4} className="text-center employee-skill-title">{t(id)}</Title>
                            <div>
                                <EmployeeSkillsGroupComponent
                                    values={values}
                                    readonly={!setValues}
                                    setValues={setValues ?? _noop}
                                    availableValueGroups={g}
                                />
                            </div>
                        </Fragment>
                    ))}
                    {/* Add a line displaying the total skill count */}
                    <div style={{
                        display: "none"
                    }}>
                        <Text size="sm" className="text-center">
                            {t("Total Skills")}: {totalSkills}
                        </Text>
                    </div>
                </Stack>
            </div>
        </div>
    );
};

export default EmployeeSkillsComponent;

