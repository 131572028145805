import { Center, Loader, Stack, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import {Url} from "../lib/http/Url";

export default function Loading() {
  const { t } = useTranslation();

  let u=Url.fromBrowser();
    if(u.getPath()==="/") {
        location.href="/app";
    }

    if(u.getPath()==="")
    {
        location.href="/app";
    }

    return (
    <Center>
      <Stack p="md" mx="auto" my="xl">
        <Loader type="bars" size={90} style={{ margin: "30px auto" }} />
        <Title pt="md">{t("loading")}</Title>
        <Text>{t("loading_desc")}</Text>
      </Stack>
    </Center>
  );
}
