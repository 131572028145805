import {MenuBarHandler} from "./MenuBarHandler";
import Application from "../Application";


export class LayoutHandler {
    private static instance: LayoutHandler;
    private _navbarState: 'opened' | 'closed' = 'closed';

    private constructor() {
    }

    public getApplication() {
        return Application.getInstance();
    }

    static getInstance(): LayoutHandler {
        if (!LayoutHandler.instance) {
            LayoutHandler.instance = new LayoutHandler();
        }
        return LayoutHandler.instance;
    }
    isOpened(): boolean {
        return this._navbarState === 'opened';
    }

    isClosed(): boolean {
        return this._navbarState === 'closed';
    }

    setNavbarState(state: 'opened' | 'closed'): void {
        this._navbarState = state;
    }

    shouldShowHamburger():boolean {
        let app = Application.getInstance();
        if(app.isRunningInterview()) {
            return false;
        }
        return true;
    }


    shouldShowNavbar():boolean {
        let app = Application.getInstance();
        if(app.isRunningInterview()) {
            return false;
        }
        return true;
    }

    shouldShowSidebar():boolean {
        let app = Application.getInstance();
        if(app.isRunningInterview()) {
            return false;
        }
        return true;
    }



    shouldShowSupportLinks():boolean {
        let app = Application.getInstance();
        if(app.isRunningInterview()) {
            return false;
        }
        return true;
    }

    shouldShowFloatingButtons():boolean {
        let app = Application.getInstance();
        if(app.isRunningInterview()) {
            return false;
        }
        return true;
    }

    shouldShowNotifications():boolean {
        let app = Application.getInstance();
        if(app.isRunningInterview()) {
            return false;
        }
        return true;
    }

    isMobile(): boolean {
        return window.innerWidth <= 768;
    }

    isSmallScreen(): boolean {
        return window.innerWidth <= 768;
    }

    isMobileDevice() {
        return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };


    getMenuBar(): MenuBarHandler {
        return MenuBarHandler.getInstance();
    }
}

export default LayoutHandler;
