import {Burger, Button, Flex, Paper, Text} from "@mantine/core";
import Logo from "src/components/Logo";
import {IconBell, IconCoins} from "@tabler/icons-react";
import {Link} from "react-router-dom";
import {useContext} from "react";
import {NotificationContext} from "src/providers/notification.provider";
import ThemeSwitch from "src/components/ThemeSwitch";
import NavigationBar from "src/components/Sections/NavigationBar";
import useIsMobile from "src/hooks/useIsMobile";
import LangSwitch from "src/components/LangSwitch";
import useUser from "src/hooks/useUser";
import Application from "../classes/Application";
import Config from "../classes/application/Config";
import LoginRegister from "../components/LoginRegister";
import LayoutHandler from "../classes/ui/LayoutHandler";

interface Props {
    showBurger: boolean;
    toggleDesktop: () => void;
    toggleMobile: () => void;
}

export default function Header({
                                   showBurger,
                                   toggleDesktop,
                                   toggleMobile,
                               }: Props) {
    const {user} = useUser();
    const {count} = useContext(NotificationContext);
    const isMobile = useIsMobile();
    const justify = isMobile ? "center" : "space-between";
    const config = Application.getConfig();
    const has_feature_credits = config.hasFeatureCredits();
    let cfg = Config.getInstance();
    let isPublic = cfg.isCurrentPublicUrl();
    let hideburger = !isMobile;
    let hidenotifications = false;
    let layh = LayoutHandler.getInstance();
    if (!layh.shouldShowHamburger()) {
        hideburger = true;
    }
    if (!layh.shouldShowNotifications()) {
        hidenotifications = true;
    }
    let app = Application.getInstance();

    if (!layh.isMobileDevice()) {
        if (app.isRunningInterview()) {
            return <>

                <Paper
                    bg="primary"
                    p={0}
                    m={isMobile ? 0 : "xs"}
                    style={{borderRadius: isMobile ? 0 : "20px 20px 0 0"}}
                >
                    <Flex align="center" w={isMobile ? "100vw" : 288} pl="xs">
                        {/* Wrapper for Burger */}
                        <div style={{display: hideburger ? "none" : "block"}}>
                            {showBurger && (
                                <Burger onClick={toggleMobile} hiddenFrom="sm" color="black"/>
                            )}
                            {showBurger && (
                                <Burger onClick={toggleDesktop} visibleFrom="sm" color="black"/>
                            )}
                        </div>
                        <Logo/>
                        <LangSwitch/>
                    </Flex>
                </Paper>

            </>
        }
    }

    return (
        <Flex justify={justify} wrap="wrap" bg={isMobile ? "#e9ecf0" : "none"}>
            <Flex>
                <Paper
                    bg="primary"
                    p={0}
                    m={isMobile ? 0 : "xs"}
                    style={{borderRadius: isMobile ? 0 : "20px 20px 0 0"}}
                >
                    <Flex align="center" w={isMobile ? "100vw" : 288} pl="xs">
                        {/* Wrapper for Burger */}
                        <div style={{display: hideburger ? "none" : "block"}}>
                            {showBurger && (
                                <Burger onClick={toggleMobile} hiddenFrom="sm" color="black"/>
                            )}
                            {showBurger && (
                                <Burger onClick={toggleDesktop} visibleFrom="sm" color="black"/>
                            )}
                        </div>
                        <Logo/>
                    </Flex>
                </Paper>
                <NavigationBar/>
            </Flex>

            <Flex gap="xs" my="xs">
                {/* Credits button */}
                {has_feature_credits && (
                    <Button component={Link} to={`/app/credits`}>
                        <Text mr="xs">{user?.Company?.account_credits ?? ""}</Text>
                        <IconCoins/>
                    </Button>
                )}
                <LoginRegister></LoginRegister>

                <ThemeSwitch/>
                <LangSwitch/>

                {/* Show notification button only if public area */}
                {!isPublic && !hidenotifications && (
                    <Button variant="default" component={Link} to="/app/notifications">
                        <Text mr="xs">{count}</Text>
                        <IconBell/>
                    </Button>
                )}
            </Flex>
        </Flex>
    );
}
