import { Box, Flex, Stack } from "@mantine/core";
import { Outlet, useParams } from "react-router-dom";
import NotificationEvent from "src/components/NotificationEvent";
import _map from "lodash/map";
import { useContext } from "react";
import { NotificationContext } from "src/providers/notification.provider";
import { useViewportSize } from "@mantine/hooks";
import useIsMobile from "src/hooks/useIsMobile";

export default function NotificationList() {
  const { notifications } = useContext(NotificationContext);
  const { notificationId } = useParams();
  const { width } = useViewportSize();
  const smallScreen = width < 850;
  const isMobile = useIsMobile();
  const hideList = notificationId && isMobile;

  /*
  alert(111);
  alert(JSON.stringify(notifications));
  */
  return (
    <Box m="md">
      <Flex
        gap="sm"
        wrap={smallScreen ? "wrap" : "nowrap"}
        justify={isMobile ? "center" : "left"}
      >
        {hideList ? null : (
          <Stack>
            {_map(notifications, (n) => (
              <NotificationEvent notification={n} key={n.id} />
            ))}
          </Stack>
        )}
        <Outlet />
      </Flex>
    </Box>
  );
}
