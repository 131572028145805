import {Badge, Flex} from "@mantine/core";
import {useTranslation} from "react-i18next";
import useExpiryValues from "src/hooks/useExpiryValues";
import getExpiryDate from "src/utils/getExpiryDate";
import SpendCreditsButton from "./SpendCreditsButton";
import {TradeType} from "src/types/TradeType";
import useApi from "src/hooks/useApi";
import Employee from "src/types/Employee.type";
import JobOffer from "src/types/JobOffer.type";
import Application from "src/types/Application.type";
import Proposal from "src/types/Proposal.type";
import {useSWRConfig} from "swr";
import useTradeTypeValues from "src/hooks/useTradeTypeValues";
import {ApiService} from "../lib/http/ApiService";
import {ApiServices} from "../lib/http/ApiServices";
import {Url} from "../lib/http/Url";


interface Props {
    item: Partial<Employee | JobOffer | Application | Proposal>;
    type?: string; // Allow any string
}

/*
interface Props {
    item: Partial<Employee | JobOffer | Application | Proposal>;
  //  type?: "employee" | "jobOffer" | "application" | "proposal"; // Make it optional
}
*/

const ExpiryStatusPill: React.FC<Props> = ({ item, type = "employee" }) => {
    const { t } = useTranslation();
    const API = useApi();
    const { mutate } = useSWRConfig();
    const expiryDate = getExpiryDate(item);
    const { status, color, label } = useExpiryValues(expiryDate);
    const tradeType = type === "employee" ? TradeType.add_employee : TradeType.add_jobOffer;
    const { cost, label: tradeLabel, desc } = useTradeTypeValues(tradeType);

    const onSuccess = async () => {
        const section = type === "jobOffer" ? "job" : type;
        let api = ApiServices.getInstance();
        await api.get(`res/${section}/activate/${item?.id}`);
        mutate(`res/${section}/${item?.id}`);
    };

    let cta;
    let url = Url.fromBrowser();
    let texp: boolean = url.getParam("testexpiry") ? true : false;

    if ((status !== "active" || texp) && (type === "employee" || type === "jobOffer")) {
        cta = (
            <SpendCreditsButton
                cost={cost}
                desc={desc}
                label={tradeLabel}
                tooltip="buy_more_time_desc"
                onSuccess={onSuccess}
            />
        );
    } else {
        cta = null;
    }

    return (
        <Flex align="center" mt="xs">
            <Badge color={color} mr="md">
                {t("status_" + status)} {label}
            </Badge>
            {cta}
        </Flex>
    );
};

export default ExpiryStatusPill;
