import { Box } from "@mantine/core";
import { Outlet } from "react-router-dom";
import Activity from "src/pages/Activity";
import ListAdmin from "src/pages/data/list/ListAdmin";
import ListApplicationDone from "src/pages/data/list/ListApplicationDone";
import ListApplicationReceived from "src/pages/data/list/ListApplicationReceived";
import ListInvites from "src/pages/data/list/ListInvites";
import ListJobOffer from "src/pages/data/list/ListJobOffer";
import ListMatches from "src/pages/data/list/ListMatches";
import ListProposalDone from "src/pages/data/list/ListProposalDone";
import ListProposalReceived from "src/pages/data/list/ListProposalReceived";
import ListEmployee from "../pages/data/list/ListEmployee";
import EmployeeListView from "../entity/Employee/view/list/EmployeeListView";
import Config from "../classes/application/Config";
import JobOfferListView from "../entity/JobOffer/view/list/JobOfferListView";
const sectionElement = (
    <Box my="md">
      <Outlet />
    </Box>
);
const cfg = Config.getInstance();

const sectionChildren = [];

sectionChildren.push({ path: "activity", element: <Activity /> });
if(cfg.hasProgressiveRegister()) {
    sectionChildren.push({ path: "employees", element: <EmployeeListView /> });
    sectionChildren.push({ path: "jobs", element: <JobOfferListView /> });

}
else {
  sectionChildren.push({ path: "employees", element: <ListEmployee /> });

    sectionChildren.push({ path: "jobs", element: <ListJobOffer /> });
}


sectionChildren.push({ path: "admins", element: <ListAdmin /> });

sectionChildren.push({ path: "applications_received", element: <ListApplicationReceived /> });

sectionChildren.push({ path: "applications_sent", element: <ListApplicationDone /> });
sectionChildren.push({ path: "proposals_received", element: <ListProposalReceived /> });
sectionChildren.push({ path: "proposals_sent", element: <ListProposalDone /> });
sectionChildren.push({ path: "matches", element: <ListMatches /> });
sectionChildren.push({ path: "reviews", element: <>TODO</> });
sectionChildren.push({ path: "invites", element: <ListInvites /> });

const sectionRoutes = [
  {
    path: "section",
    element: sectionElement,
    children: sectionChildren,
  },
];

export default sectionRoutes;
