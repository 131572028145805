import React, {useState, useEffect} from 'react';
import {Modal, Button, Text} from '@mantine/core';
import {ConfirmDialogParams} from "./ConfirmDialogParams";
import {useMantineTheme} from '@mantine/core';
import "./ConfirmDialog.module.css";

interface ConfirmDialogProps {
    params: ConfirmDialogParams;
    isOpen?: boolean;
    onClose?: () => void;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'; // Use size prop to control width
}

interface Dialogs {
    [key: string]: {
        open: () => void;
        close: () => void;
    };
}

declare global {
    interface Window {
        dialogs?: Dialogs;
    }
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({params, isOpen = true, onClose, size = 'l'}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(isOpen);
    const theme = useMantineTheme();

    // Set up modal open and close handlers
    params.setOpenModal(() => {
        setIsDialogOpen(true);
    });

    params.setCloseModal(() => {
        setIsDialogOpen(false);
    });

    let onConfirm = typeof params.getOnConfirm() === 'function' ? params.getOnConfirm() : () => alert('Confirm button clicked!');
    let onCancel = typeof params.getOnCancel() === 'function' ? params.getOnCancel() : () => {
        setIsDialogOpen(false);
        if (onClose) onClose();
    };

    useEffect(() => {
        if (!window.dialogs) {
            window.dialogs = {};
        }

        window.dialogs["last_dialog"] = {
            open: () => setIsDialogOpen(true),
            close: () => setIsDialogOpen(false),
        };

        return () => {
            // @ts-ignore
            delete window.dialogs["last_dialog"];
        };
    }, []);

    if (!onClose) {
        onClose = () => {
        };
    }

    if (!onConfirm) {
        onConfirm = () => {
        };
    }
    if (!onCancel) {
        onCancel = () => {
        };
    }

    // Styles as variables
    const modalContentStyle = {
        border: `4px solid ${theme.colors.primary[6]}`,
        borderRadius: '10px',
        marginLeft: 0, // Ensure no margins
        marginRight: 0, // Ensure no margins
        padding: 0, // Remove any extra padding
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    };

    const modalBodyStyle = {
        padding: '20px',
        flexGrow: 1, // Allow the body to take available space
    };

    const modalTitleStyle = {
        color: theme.colors.primary[6],
        fontWeight: 600,
        fontSize: '20px',
    };

    const textStyle = {
        fontSize: '16px',
        marginBottom: '20px',
        color: '#333',
    };

    const buttonBaseStyle = {
        padding: '10px 20px',
        borderRadius: '5px',
        fontSize: '15px',
        height: 'auto', // Ensures the height adjusts based on content
        lineHeight: 'normal', // Ensures proper line spacing for the text
    };

    const confirmButtonStyle = {
        ...buttonBaseStyle,
        backgroundColor: `${theme.colors.primary[6]}`,
        color: 'white',
        fontWeight: 'bold',
    };

    const cancelButtonStyle = {
        ...buttonBaseStyle,
        backgroundColor: 'white',
        color: 'black',
        fontWeight: 'bold',
        border: '1px solid orange'
    };

    return (
        <Modal
            opened={isDialogOpen}
            onClose={onCancel}
            title={params.getTitle()}
            size={size} // Set modal width based on the size prop

            // @ts-ignore
            // @ts-nockeck
            styles={{
                //               content: modalContentStyle,
                body: modalBodyStyle,
                title: modalTitleStyle,
                close: {color: 'orange', borderColor: 'orange'} // Apply orange color to the close button
            }}
        >
            <Text style={textStyle}>
                {params.getMessage()}
            </Text>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Button
                    style={confirmButtonStyle}
                    onClick={onConfirm}
                >
                    {params.getConfirmButtonLabel()}
                </Button>

                &nbsp;
                <Button
                    style={cancelButtonStyle}
                    onClick={onCancel}
                >
                    {params.getCancelButtonLabel()}
                </Button>
            </div>
        </Modal>
    );
};

export default ConfirmDialog;
