import { Box, Divider, Group, Paper, Stack } from "@mantine/core";
import CompanyContacts from "src/components/CompanyContacts";
import Company from "src/types/Company.type";
import CompanySubscription from "../CompanySubscription";
import CardButtonLinks from "../CompanyLinks";
import ActionButton from "../ActionButton";
import companyLinks from "src/constants/companyLinks.const";
import CompanySection from "../Sections/Company.Section";
import SeeMore from "../SeeMore";
import useIsConnected from "src/hooks/useIsConnected";
import useIsOwn from "src/hooks/useIsOwn";
import Error from "src/pages/Error";
import useIsMobile from "src/hooks/useIsMobile";
import Config from "../../classes/application/Config";
import CardButtonLinksPublic from "../CompanyLinksPublic";
import { useTranslation } from "react-i18next";
import Application from "../../classes/Application";
import appRouter from "../../classes/AppRouter";

interface Props {
    company?: Company;
}

export default function CompanyView({ company }: Props) {
    const isMobile = useIsMobile();
    const isOwn = useIsOwn(company);
    const isConnected = useIsConnected(company);
    const cfg = Config.getInstance();
    const isPublic = cfg.isCurrentPublicUrl();
    const { t } = useTranslation();
    const hasCompany = !!company;

    let apr = appRouter.getInstance();
    apr.setNextToUrl();
    apr.setCancelToUrl();

    if (isPublic) {
        return <CardButtonLinksPublic links={companyLinks} />;
    }

    if (!hasCompany) {
        if (!cfg.hasProgressiveRegister()) {
            return <Error title="approval_req" desc="approval_req_desc" />;
        }

        return (
            <Box maw={isMobile ? "auto" : 1000} mx="auto" pb={isMobile ? "xl" : "xs"}>
                <Group justify="right" my="xs"></Group>
                <div style={{
                    display: "none"
                }}>
                    <Paper  className="tour_start" style={{ style:"width:200px", textAlign: "center" }}>
                        <ActionButton isReady={true} label={t("register_your_company")} to={"/company/register"} />
                    </Paper>

                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%", // Ensures it takes full width of the parent
                    }}
                >
                    <Paper className="tour_start" style={{ width: "200px", textAlign: "center" }}>
                        <ActionButton isReady={true} label={t("register_your_company")} to={"/company/register"} />
                    </Paper>
                </div>



                <Divider my="lg" />
                <Group align="top" wrap="wrap">
                    <Stack w={isMobile ? "80%" : "57%"} miw={isMobile ? "none" : "380"} mx="auto">
                        <CardButtonLinks links={companyLinks} />
                    </Stack>
                </Group>
            </Box>
        );
    }

    return (
        <Box maw={isMobile ? "auto" : 1000} mx="auto" pb={isMobile ? "xl" : "xs"}>
            <Group justify="right" my="md">
                {isOwn && (
                    <ActionButton
                        variant="default"
                        to={`/app/edit/company/${company.id}`}
                        label={"edit_profile"}
                        isReady
                    />
                )}
            </Group>
            <Paper mx="xs" className="tour_start">
                <CompanySection company={company} />
            </Paper>
            <Divider my="lg" />

            <Group align="top" wrap="wrap">
                {isOwn ? (
                    <Stack w={isMobile ? "80%" : "57%"} miw={isMobile ? "none" : "380"} mx="auto">
                        <CardButtonLinks links={companyLinks} />
                        <CompanySubscription company={company} />
                    </Stack>
                ) : (
                    <SeeMore isOwn={isOwn} isConnected={isConnected} type="company" />
                )}
                <Stack w={isMobile ? "97%" : "28%"} miw={isMobile ? "none" : "380"} mx="auto">
                    <Paper m="0">
                        <CompanyContacts company={company} isMatched={isConnected} />
                    </Paper>
                </Stack>
            </Group>
        </Box>
    );
}
