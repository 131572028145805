import { Flex, Paper, Stack, Text, Title } from "@mantine/core";
import Application from "src/types/Application.type";

import Actions from "../Actions";
import HorizontalScroll from "../HorizontalScroll";
import { Row } from "../Row";
import JobOfferCard from "../cards/Job.Card";
import EmployeeCard from "../cards/Employee.Card";
import GroupAvailability from "../GroupAvailability";
import _map from "lodash/map";
import useApi from "src/hooks/useApi";
import { ApplicationStatus } from "src/types/ApplicationStatus.type";
import { useNavigate } from "react-router-dom";
import ApplicationStatusPill from "../ApplicationStatusPill";
import CompanyFromTo from "../CompanyFromTo";
import SeeMore from "../SeeMore";
import { useTranslation } from "react-i18next";
import ExpiryStatusPill from "../ExpiryStatusPill";
import Description from "../Description";
import useIsOwn from "src/hooks/useIsOwn";
import Config from "../../classes/application/Config";
import {ProposalStatus} from "../../types/ProposalStatus.type";
import {ApiServices} from "../../lib/http/ApiServices";
import {Applicationx} from "../../classes/Application";
import app from "../../App";

interface Props {
  application: Application;
}

const ApplicationView = ({ application }: Props) => {
  const {
    id,
    desc,
    durationMin,
    durationMax,
    price,
    Employees,
    JobOffer,
    status,
  } = application;

  const API = useApi();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const editApplication = async (newStatus: ApplicationStatus) => {
//    API.PUT(`res/application/${id}`, { status: newStatus });


       let api = ApiServices.getInstance();
          api.setToken(Applicationx.getInstance().getToken())

          try {
              await api.post(`res/application/changestatus/${id}`,{ status: newStatus });
          }
          catch (err:any) {

          }


      navigate(`/app/view/application/${id}`);



  };

  const isOwn = useIsOwn(application);let actions = [];
    let cfg = Config.getInstance();

    if (isOwn) {
        const disableSendApplication = cfg.hasDebug() ? false : status !== ApplicationStatus.draft;
        const disableCancelApplication = cfg.hasDebug() ? false : status !== ApplicationStatus.open;
        if (cfg.hasDebug()) {
        }

        actions = [
            {
                label: "send_application",
                onClick: () => editApplication(ApplicationStatus.open),
                disabled: disableSendApplication,
            },
            {
                label: "cancel_application",
                onClick: () => editApplication(ApplicationStatus.cancelled),
                disabled: disableCancelApplication,
            },
            // {
            //   label: "add_review",
            //   to: `/app/add/review/${JobOffer.id}`,
            //   disabled: cfg.hasDebug() ? false : status !== ApplicationStatus.accepted,
            // },
        ];
    } else {
        const disableDeclineApplication = cfg.hasDebug() ? false : status !== ApplicationStatus.open;
        const disableAcceptApplication = cfg.hasDebug() ? false : status !== ApplicationStatus.open;

        if (cfg.hasDebug()) {
            // Debug-specific logic can go here
        }

        actions = [
            {
                label: "decline_application",
                onClick: () => editApplication(ApplicationStatus.denied),
                disabled: disableDeclineApplication,
            },
            {
                label: "accept_application",
                onClick: () => editApplication(ApplicationStatus.accepted),
                disabled: disableAcceptApplication,
            },
        ];
    }
//window.ww(application)

//    ww(company.name)
    return (
    <Stack gap="md">
      <Title>{t(
          "application")}</Title>

      <Row title={t("company")}>
        <Flex direction="column">
          <Paper my="md">
            <CompanyFromTo el={application} />
          </Paper>
          <SeeMore
            isOwn={isOwn}
            type="application"
            isConnected={status === ApplicationStatus.accepted}
          />
        </Flex>
      </Row>

      <Row title={t("job_offer")}>
        <JobOfferCard jobOffer={JobOffer} />
      </Row>

      <Row title={t("status")}>
        <ApplicationStatusPill status={status} />
      </Row>

      {isOwn && (
        <Row title={t("expiry_date_title")}>
          <ExpiryStatusPill item={application} type="application" />
        </Row>
      )}

      <Row title={t("application_description")}>
        <Description text={desc} />
      </Row>

      <Row title={t("price")}>
        {price} {t("euro_hour")}
      </Row>

      <Row title={t("duration_expected")}>
        <Stack>
          <Text>
            {t("minimum") + ": "} {durationMin} {t("days")}
          </Text>
          <Text>
            {t("maximum") + ": "}
            {durationMax} {t("days")}
          </Text>
        </Stack>
      </Row>

      <Row title={t("availability")}>
        <GroupAvailability employees={Employees} />
      </Row>

      <Row title={t("selected_employees")}>
        <HorizontalScroll>
          {_map(Employees, (employee) => (
            <EmployeeCard employee={employee} key={employee.id} />
          ))}
        </HorizontalScroll>
      </Row>

      <Actions actions={actions} />
    </Stack>
  );
};

export default ApplicationView;
