import ApplicationForm from "src/components/forms/Application.form";
import {useNavigate, useParams} from "react-router-dom";
import Application from "src/types/Application.type";
import useApi, {useGET} from "src/hooks/useApi";
import Loader from "src/components/Loader";
import JobOffer from "src/types/JobOffer.type";
import CONFIG_KEYS from "src/constants/config.const";
import usePageView from "../../../hooks/usePageView";
import TrackingSystem from "../../../lib/Tracking/TrackingSystem";
import Config from "../../../classes/application/Config";
import {Applicationx} from "../../../classes/Application";
import CompanyAddedInfoDialog from "../../../components/ui/widget/CompanyAddedInfoDialog/CompanyAddedInfoDialog";
import RequiredCompanyConfirmDialog
    from "../../../components/ui/widget/ProgressiveRegisterConfirmDialog/RequiredCompanyConfirmDialog";

export default function AddApplication() {
    const navigate = useNavigate();
    const api = useApi();
    const {jobId} = useParams();
    const url = `res/job/${jobId}`;
    const {data, isLoading, error} = useGET<JobOffer>(url);
    usePageView("form_application");
    const initialValues: Partial<Application> = {
        JobOffer: data,
        jobOfferId: jobId,
    };

    const onSubmit = async (values: Partial<Application>) => {
        const res = await api.POST(`res/application`, values);
        if (res?.success) {

            let ts = TrackingSystem.getInstance().sendCustomEvent("application_sent");
            navigate("/app/section/applications_sent");
        }

    };


    const cfg = Config.getInstance();
    let app = Applicationx.getInstance();
    if (cfg.hasProgressiveRegister() && !app.hasCompany()) {
        return (
            <>

            <ApplicationForm
                title="create_application"
                onSubmit={onSubmit}
                initialValues={initialValues}
                costId={CONFIG_KEYS.ADD_APPLICATION_COST}
                expiryId={CONFIG_KEYS.APPLICATION_EXPIRY_PERIOD}
            />
            </>
        );
    }
    return (
        <Loader isLoading={isLoading} error={error} hasData={!!data}>
            <ApplicationForm
                title="create_application"
                onSubmit={onSubmit}
                initialValues={initialValues}
                costId={CONFIG_KEYS.ADD_APPLICATION_COST}
                expiryId={CONFIG_KEYS.APPLICATION_EXPIRY_PERIOD}
            />
        </Loader>
    );
}
