import React, {ReactNode} from 'react';
import {Container, Title} from '@mantine/core';
import {useLocation} from 'react-router-dom';
import './InterviewComponent.css';
import VatQuestionComponent from "./Step1/VatQuestionComponent";
import BusinessTypeQuestionComponent from "./HasVat/BusinessTypeQuestionComponent";
import CompanyInterestsQuestionComponent from "./HasVat/Company/CompanyInterestsQuestionComponent";
import Application from "../../classes/Application";
import Config from "../../classes/application/Config";
import ProfessionaHasEmployeesQuestionComponent from "./HasVat/Freelancer/ProfessionaHasEmployeesQuestionComponent";
import ProfessionalInterestsQuestionComponent from "./HasVat/Freelancer/ProfessionalnterestsQuestionComponent";
import ConsiderBecomingEmployeeQuestionComponent from "./HasVat/Freelancer/ConsiderBecomingEmployeeQuestionComponent";
import BusinessInsVatQuestionComponent from "./HasVat/BusinessInsVatQuestionComponent";
import EmployeesConsiderVatQuestionComponent from "./NoVat/EmployeesConsiderVatQuestionComponent";
import InterviewFinishComponent from "./Finish/InterviewFinishComponent";
import EmployeeInterestsQuestionComponent from "./NoVat/EmployeeInterestsQuestionComponent";
import {useEffect} from 'react';
import {useState} from 'react';

interface InterviewComponentProps {
    children?: ReactNode;
}

const InterviewComponent: React.FC<InterviewComponentProps> = ({children}) => {
    let app = Application.getInstance();

    app.runInterview();
    const [userModelLoaded, setUserModelLoaded] = useState(false);

    useEffect(() => {
        const loadUserModel = async () => {
            await app.waitForUserModel();
          //  alert(app.getUserModel()?.get_id());
            app.runInterview();
            setUserModelLoaded(true);
        };

        loadUserModel();
    }, []);
    if (!userModelLoaded) {
        return <div>Loading...</div>;
    }

    /*
    await app.waitForUserModel();

    alert(app.getUserModel()?.get_id());
    */

    const location = useLocation();
    const isVatPage = location.pathname.includes('/vat');
    const isBusinessTypePage = location.pathname.includes('/businesstype');
    const isFreelancerInterests = location.pathname.includes('/prof/interests');
    const isProfessionalEmployees = location.pathname.includes('/prof/employees');
    const isCompanyInterests = location.pathname.includes('/company/interests');
    const isConsiderEmployee = location.pathname.includes('/prof/consideremployee');
    const isInsvat = location.pathname.includes('/insvat');
    const isConsiderVat = location.pathname.includes('/employee/considervat');
    const isEmployeeInterests = location.pathname.includes('/employee/interests');
    const isFinish = location.pathname.includes('/interview/finished');


    // alert(isConsiderVat);

    let hasTitle = false;
    let hasTopbar = false;
    let cfg = Config.getInstance();
    let isMobile = cfg.isMobileDevice();
    let marginTop: string = "0px";
    if (!isMobile) {
        marginTop = "5%";
    }
    return (

        <>

            <div style={{
                border: "2px solid red",
                height: "300px",
                width: "100%",
                display: "none",
            }}
            >
            </div>

            <Container style={{
                width: "100vw",
                textAlign: 'center',
                maxWidth: isMobile ? "100%" : "90%",
                padding: 0,
                border: '0px solid green',

            }}>
                {
                    hasTitle && <Title>Interview</Title>
                }
                {
                    hasTopbar && <div className="top-bar"></div>
                }

                <div style={{
                    marginTop: marginTop, textAlign: 'center', width: "100%"

                }}>

                    {isVatPage && <VatQuestionComponent/>}
                    {isBusinessTypePage && <BusinessTypeQuestionComponent/>}
                    {isFreelancerInterests && <ProfessionalInterestsQuestionComponent/>}
                    {isCompanyInterests && <CompanyInterestsQuestionComponent/>}
                    {isProfessionalEmployees && <ProfessionaHasEmployeesQuestionComponent/>}
                    {isConsiderEmployee && <ConsiderBecomingEmployeeQuestionComponent/>}
                    {isInsvat && <BusinessInsVatQuestionComponent/>}
                    {isConsiderVat && <EmployeesConsiderVatQuestionComponent/>}
                    {isEmployeeInterests && <EmployeeInterestsQuestionComponent/>}
                    {isFinish && <InterviewFinishComponent/>}
                    {children}
                </div>

            </Container>


        </>
    );
};

export default InterviewComponent;
