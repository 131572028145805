import React, { useState } from 'react';
import { Button, Container, Stack, Group } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import './ProfessionaHasEmployeesQuestionComponent.css';
import SheetComponent from "../../SheetComponent";
import Config from "../../../../classes/application/Config";
import { useMantineTheme } from '@mantine/core';
import InterviewUserProfileModel from "../../../../classes/interview/InterviewUserProfileModel";
import {useNavigate} from "react-router-dom";

const ProfessionalInterestsQuestionComponent: React.FC = () => {
    const { t } = useTranslation();
    const [selectedOptions, setSelectedOptions] = useState<Set<string>>(new Set());
    const [hoveredButton, setHoveredButton] = useState<string | null>(null); // Track which button is hovered
    const navigate = useNavigate();

    const handleToggleOption = (option: string) => {
        setSelectedOptions(prevState => {
            const newState = new Set(prevState);
            if (newState.has(option)) {
                newState.delete(option);
            } else {
                newState.add(option);
            }
            return newState;
        });
    };

    let cfg = Config.getInstance();
    let isMobile = cfg.isMobileDevice();

    let intv = InterviewUserProfileModel.getInstance();

    intv.setLastPage('/app/interview/prof/interests');

    // Declare variables for minWidth and minHeight
    const minWidth = '300px';
    const minHeight = '150px';
    const buttonPadding = '10px';
    const maxWidthMobile = '95%';
    const maxWidthDesktop = '30%';

    // Get Mantine theme for primary color
    const theme = useMantineTheme();
    const primaryColor = theme.colors[theme.primaryColor][6];
    const getBackgroundColor = (option: string, selectedOptions: Set<string>): string => {
        if (selectedOptions.has(option)) {
            return primaryColor; // Selected button color
        }
        if (hoveredButton === option) {
            return '#dddddd'; // Hover effect color
        } else if (selectedOptions.has(option)) {
            return primaryColor; // Selected button color
        } else {
            return '#efefef'; // Default background color
        }
    };

    let options = [];
    options.push('hire_employees');
    options.push('publish_jobs');

    if(intv.get_hasEmployees()) {
        options.push('propose_my_employees');
        options.push('seek_jobs');
    }
    else {
        options.push('seek_jobs');
    }

    console.log(options);

    const handleConfirm = () => {
        if (selectedOptions.size > 0) {
        } else {
            alert('No options selected.');
        }
        // Handle confirmation logic based on selected options
        if (selectedOptions.has('hire_employees')) {
            intv.set_hiresEmployees(true);
        }
        else {
            intv.set_hiresEmployees(false);
        }


        if (selectedOptions.has('publish_jobs')) {
            intv.set_publishJobs(true);
        }
        else {
            intv.set_publishJobs(false);
        }

        if (selectedOptions.has('propose_my_employees')) {
            intv.set_outsourcesEmployees(true);
        }else {
            intv.set_outsourcesEmployees(false);
        }
        if (selectedOptions.has('seek_jobs')) {
            intv.set_seeksJobs(true);
        }
        intv.storeLocal();


        if(intv.get_hasEmployees()) {
            navigate("/app/interview/insvat")
            return;
        }
        if (!intv.get_hiresEmployees()) {
            navigate("/app/interview/prof/consideremployee")
            return;
        }
        navigate("/app/interview/insvat")

        // Additional logic can be added based on specific options selected
        // Example: Navigate to another page or update some state

    };

    return (
        <SheetComponent title="" backto="/app/interview/prof/employees">
            <Container
                style={{
                    textAlign: 'center',
                    width: "100vw",
                    maxWidth: "100%",
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                    backgroundColor: "#dddddd",
                    alignItems: 'center',
                    borderRadius: "15px",
                }}
            >
                <h2>{t('company_what_are_you_interested_in')}</h2>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap', // Allows buttons to wrap on smaller screens
                        justifyContent: 'center', // Keeps items centered
                        gap: '10px', // Adds spacing between buttons
                        width: '100%',
                        flexDirection: isMobile ? 'column' : 'row',
                        textAlign: 'center',
                        alignItems: 'center', // Centra gli elementi figli
                    }}
                >
                    {options.map((option, index) => (
                        <React.Fragment key={option}>
                            <Button
                                onClick={() => handleToggleOption(option)}
                                className={`button company-${option}`}
                                onMouseEnter={() => setHoveredButton(option)} // Set hover to true for the current button
                                onMouseLeave={() => setHoveredButton(null)} // Reset hover when mouse leaves
                                style={{
                                    width: isMobile ? maxWidthMobile : maxWidthDesktop, // Use variables here
                                    maxWidth: isMobile ? maxWidthMobile : maxWidthDesktop,
                                    minHeight: minHeight, // Use the minHeight variable
                                    maxHeight: '150px',
                                    minWidth: minWidth, // Use the minWidth variable
                                    whiteSpace: 'normal',
                                    wordBreak: 'break-word',
                                    textAlign: 'center',
                                    padding: buttonPadding, // Use padding variable
                                    lineHeight: '1.6',
                                    flex: '1 1 auto', // Ensures buttons adjust dynamically
                                    backgroundColor: getBackgroundColor(option, selectedOptions),
                                    borderColor: 'white',
                                    borderRadius: '10px',
                                    marginBottom: '10px',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)', // Subtle shadow for depth
                                    color: selectedOptions.has(option) || hoveredButton === option ? 'black' : 'black', // Change text color when selected or hovered
                                }}
                                variant={selectedOptions.has(option) ? 'filled' : 'outline'}
                            >
                                <div style={{ width: '100%', whiteSpace: 'pre-line' }}>
                                    {t(`freelancer_${option}`)}
                                </div>
                            </Button>
                            {(index + 1) % 2 === 0 && <div style={{ width: '100%', height: '0' }} />} {/* Force a new line after every two buttons */}
                        </React.Fragment>
                    ))}
                </div>

                {selectedOptions.size > 0 && (
                    <div style={{ marginTop: '20px', display: "none" }}>
                        <p>{t('company_selected_options')}:</p>
                        <ul style={{ paddingLeft: '20px', wordBreak: 'break-word' }}>
                            {Array.from(selectedOptions).map((option, index) => (
                                <li key={index} style={{ marginBottom: '10px' }}>
                                    {t(`company_${option}`)}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <br />
                <Button
                    fullWidth
                    style={{
                        marginTop: '20px',
                        width: isMobile ? '95%' : '40%',
                        fontSize: "20px",
                        margin: "20px"
                    }}
                    disabled={selectedOptions.size === 0}
                    onClick={handleConfirm} // Add onClick handler to confirm
                >
                    {t('confirm_choices')}
                </Button>
            </Container>
        </SheetComponent>
    );
};

export default ProfessionalInterestsQuestionComponent;
