import React, { useContext, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import routes from "./routes";
import { Amplify } from "aws-amplify";
import type { WithAuthenticatorProps } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { NotificationContext } from "./providers/notification.provider";
import useAuth from "./hooks/useAuth";
import Maintenance from "./pages/Maintenance";
import { useGET } from "./hooks/useApi";
import Application from "./classes/Application";
import ReactGA from "react-ga4";
import usePageTracking from "./hooks/usePageTracking";
import TrackingSystem from "./lib/Tracking/TrackingSystem";
import Config from "./classes/application/Config";
import zoneList from "./components/badges/ZoneList";
import config from "./amplifyconfiguration.json";
import configstg from "./amplifyconfiguration.staging.json";
import configprod from "./amplifyconfiguration.production.json";
import configlocal from "./amplifyconfiguration.local.json";
import {Url} from "./lib/http/Url";



declare let __AMPLIFY_CONFIG__: string;
//declare const __AMPLIFY_CONFIG__: string;


//import config from 'amplifyconfig?compileTime';


//alert(configstg);
let cfg=Config.getInstance();

let url = Url.fromBrowser();


let read_conf:number=0;

if(url.hostnameContains("localhost")) {

  read_conf=1;
  Amplify.configure(configlocal);
}
if(url.hostnameContains("staging.autom8deal")) {

  read_conf=2;
  /*
  alert("reading staging config");
  console.log(config);
  alert(JSON.stringify(config));
  */
  if(window.location.href.includes("see_amp")) {
    alert(configstg);
    alert(JSON.stringify(configstg));
    console.log(configstg);


  }
  console.log(configstg);
  Amplify.configure(configstg);
}

if(url.hostnameContains("app.autom8deal")) {

  read_conf=3;
  Amplify.configure(configprod);
  console.log("cog-prod-read");
}

if(url.hostnameContains("cloudfront.net")) {

  read_conf=4;
  Amplify.configure(configprod);
  console.log("cog-prod-read-front");
}
if(read_conf==0) {
  Amplify.configure(configprod);

  read_conf=5;
  console.log("cog-prod-read-default-prod");

}

const firstVisitKey = "first_visit";

if (!localStorage.getItem(firstVisitKey)) {
  localStorage.setItem(firstVisitKey, "true");
  let ts = TrackingSystem.getInstance().sendCustomEvent("first_visit");
} else {
  // Not the first visit actions
}



/*
else {


  //alert("reading regular config");
  Amplify.configure(config);
}
*/

/*
if(cfg.getEnv()==="staging") {
  alert("reading staging config");
  Amplify.configure(configstg);
}
else {


  alert("reading regular config");
  Amplify.configure(config);
}
*/




// @ts-ignore
/*
//import config from __AMPLIFY_CONFIG__; // This will be replaced at compile time
*/
//Amplify.configure(config);



// We will load the configuration asynchronously before rendering the app
/*
let configPromise = (async () => {
  if (import.meta.env.MODE === "staging") {
    return await import("./amplifyconfiguration.staging.json");
  } else {
    return await import("./amplifyconfiguration.json");
  }
})();
*/
// Await for the config to load before initializing Amplify
/*
configPromise.then((configModule) => {
  Amplify.configure(configModule.default || configModule); // Handle the default export if necessary
});
 */



const App = ({ user }: WithAuthenticatorProps) => {
  const { email } = useAuth();
  const notifCtx = useContext(NotificationContext);
  const { data, isLoading, error } = useGET(`health`);
  // Initialize the application instance
  Application.getInstance(import.meta.env);
//  console.log("Running new version with config");

  // Track page views (must be inside the Router context)
   usePageTracking();

  useEffect(() => {
    if (email) {
      console.log("logged in as ", email);
      TrackingSystem.getInstance().sendCustomEvent("logged_in");
    }
  }, [email]);

  useEffect(() => {
    notifCtx?.setReady(!!user);
  }, [user, notifCtx]);

  // Check if maintenance mode or error should be displayed
  if (!Application.getConfig().isUrlConfig()) {
    if (!isLoading && (error || !data)) {
      return <Maintenance />;
    }
  }

  return <RouterProvider router={routes} />;
};

export default App;
