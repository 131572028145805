import React, { useState } from 'react';
import { Button, Container } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import SheetComponent from '../SheetComponent';
import './BusinessTypeQuestionComponent.css';

const BusinessTypeQuestionComponent: React.FC = () => {
    const { t } = useTranslation();
    const [isCompany, setIsCompany] = useState<boolean | null>(null);

    const handleAnswer = (answer: boolean) => {
        setIsCompany(answer);
    };

    return (
        <SheetComponent title="business_type_question">
            <Container style={{ textAlign: 'center' }}>
                <h2>{t('business_type_question')}</h2>
                <Button
                    onClick={() => handleAnswer(true)}
                    variant="filled"
                    color="primary"
                    style={{ margin: '10px' }}
                >
                    {t('company')}
                </Button>
                <Button
                    onClick={() => handleAnswer(false)}
                    variant="filled"
                    color="primary"
                    style={{ margin: '10px' }}
                >
                    {t('freelancer')}
                </Button>

                {isCompany !== null && (
                    <div style={{ marginTop: '20px' }}>
                        <p>{isCompany ? t('answered_company') : t('answered_freelancer')}</p>
                    </div>
                )}
            </Container>
        </SheetComponent>
    );
};

export default BusinessTypeQuestionComponent;
