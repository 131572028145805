import {Fragment} from "react";
import {Table, Text} from "@mantine/core";
import Proposal from "src/types/Proposal.type";
import {TableRow} from "src/types/TableRow.type";
import ProposalStatusPill from "src/components/ProposalStatusPill";

const ProposalTableRow = ({item, onClick}: TableRow<Proposal>) => (
    <Fragment key={item.id}>
        <Table.Td onClick={onClick}>
            <Text fw={700} tt={"capitalize"}>aaaa
                {item?.toEmployee?.name} {item?.toEmployee?.surname}
            </Text>
        </Table.Td>
        <Table.Td onClick={onClick}>aaa{item?.JobOffer?.title}</Table.Td>
        <Table.Td onClick={onClick}>
            <Text truncate="end">{item?.desc}</Text>
        </Table.Td>
        <Table.Td onClick={onClick}>
            <ProposalStatusPill status={item?.status}/>
        </Table.Td>
    </Fragment>
);

export default ProposalTableRow;
