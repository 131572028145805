import React, { useState, useEffect } from 'react';
import { Modal, Button, Text } from '@mantine/core';
import { InfoDialogParams } from "./InfoDialogParams";
import { useMantineTheme } from '@mantine/core';
import "./InfoDialog.module.css";

interface InfoDialogProps {
    params: InfoDialogParams;
    isOpen?: boolean;
    onClose?: () => void;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'; // Use size prop to control width
}

interface Dialogs {
    [key: string]: {
        open: () => void;
        close: () => void;
    };
}

declare global {
    interface Window {
        dialogs?: Dialogs;
    }
}

const InfoDialog: React.FC<InfoDialogProps> = ({ params, isOpen = true, onClose, size = 'l' }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(isOpen);
    const theme = useMantineTheme();

    // Set up modal open and close handlers
    params.setOpenModal(() => {
        setIsDialogOpen(true);
    });

    params.setCloseModal(() => {
        setIsDialogOpen(false);
    });

    let onConfirm = typeof params.getOnConfirm() === 'function' ? params.getOnConfirm() : () => alert('Confirm button clicked!');

    useEffect(() => {
        if (!window.dialogs) {
            window.dialogs = {};
        }

        window.dialogs["last_dialog"] = {
            open: () => setIsDialogOpen(true),
            close: () => setIsDialogOpen(false),
        };

        return () => {
            // @ts-ignore
            delete window.dialogs["last_dialog"];
        };
    }, []);

    if (!onClose) {
        onClose = () => {};
    }

    if (!onConfirm) {
        onConfirm = () => {};
    }

    // Styles as variables
    const modalContentStyle = {
        border: `4px solid ${theme.colors.primary[6]}`,
        borderRadius: '10px',
        marginLeft: 0,
        marginRight: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    };

    const modalBodyStyle = {
        padding: '20px',
        flexGrow: 1,
    };

    const modalTitleStyle = {
        color: theme.colors.primary[6],
        fontWeight: 600,
        fontSize: '20px',
    };

    const textStyle = {
        fontSize: '16px',
        marginBottom: '20px',
        color: '#333',
    };

    const buttonBaseStyle = {
        padding: '10px 20px',
        borderRadius: '5px',
        fontSize: '15px',
        height: 'auto',
        lineHeight: 'normal',
    };

    const okButtonStyle = {
        ...buttonBaseStyle,
        backgroundColor: `${theme.colors.primary[6]}`,
        color: 'white',
        fontWeight: 'bold',
        width: '100%', // Makes button full width for a centered look
    };

    if(!onConfirm) {

        onConfirm=()=> {

        }
    }

    if(onConfirm==null) {

        onConfirm=()=> {

        }
    }

    return (
        <Modal
            opened={isDialogOpen}
            onClose={onClose}
            title={params.getTitle()}
            size={size}
            styles={{
             //   content: modalContentStyle,
                body: modalBodyStyle,
                title: modalTitleStyle,
                close: { color: 'orange', borderColor: 'orange' },
            }}
        >
            <Text style={textStyle}>
                {params.getMessage()}
            </Text>

            <Text style={textStyle}>
                {params.getSubMessage()}
            </Text>
            <Button
                style={okButtonStyle}
                onClick={() => {
                    if(onConfirm) {
                        onConfirm();
                    }
                    setIsDialogOpen(false); // Close dialog after confirm
                    if (onClose) onClose(); // Call onClose callback
                }}
            >
                    OK
            </Button>
        </Modal>
    );
};

export default InfoDialog;
