import {Fragment, useState} from "react";
import {Table, Text} from "@mantine/core";
import {IconMail, IconBriefcase} from "@tabler/icons-react"; // Icons for application and proposal
import Avatar from "src/components/Avatar";
import CompanySocials from "src/components/cards/CompanySocials";
import {DateTag} from "../../badges/DateTag";
import {useNavigate} from "react-router-dom";
import Application from "../../../classes/Application";
import MatchesJobDetail from "./MatchesJobDetail";
import MatchesEmployeesDetail from "./MatchesEmployeesDetail";
import Config from "../../../classes/application/Config";
import {useTranslation} from "react-i18next";

const MatchesTableRow = ({item, onClick}: any) => {
    const [hoveredRow, setHoveredRow] = useState<string | null>(null);  // Track hovered row
    let phrase: string = "";
    const {t} = useTranslation();

    if (!item) {
        return (
            <>
                <Table.Tr>
                    <Table.Td colSpan={20}>{phrase}</Table.Td>
                </Table.Tr>
                <Table.Tr>
                    <Table.Td>No data available</Table.Td>
                </Table.Tr>
            </>
        );
    }

    const navigate = useNavigate();
    let company = item.CompanyFrom;
    if (!company) {
        company = item.CompanyTo;
    }
    const itemData = item.itemData;
    let showIconsColumn: boolean = true;
    let ap = Application.getInstance();
    let job: any = itemData.job;

    let showJson: boolean = false;

    let showProposalDetails: boolean = false;
    let showJobDetails: boolean = false;
    let showEmployeesDetails: boolean = false;

    const cfg = Config.getInstance();
    if (cfg.isEnvDevelopment()) {
        if (location.href.includes("debug")) {
            showProposalDetails = true;
            showJobDetails = true;
            showEmployeesDetails = true;
        }
    }

    let user: any = Application.getInstance().getUser();

    const job_owner = job.meta_created_by === user.id ? "me" : "party";

    if (job_owner == "me") {
        if (itemData.type == "application") {
            phrase = "You accepted the company developers";
        }

        if (itemData.type == "proposal") {
            phrase = "The company has accepted your job offer";
        }
    }

    if (job_owner == "party") {
        if (itemData.type == "application") {
            phrase = "The company has accepted your developers";
        }
        if (itemData.type == "proposal") {
            phrase = "You have accepteted the company job offer";
        }
    }

    const handleInternalClick = () => {
        let showCase: boolean = false;
        console.log("Row clicked:", itemData.id);

        if (job_owner == "me") {
            if (itemData.type == "application") {
                navigate("/app/view/application/"+itemData.id)
           //     phrase = t("matches_you_accepted_party_developers");
            }

            if (itemData.type == "proposal") {

                navigate("/app/view/proposal/"+itemData.id)
         //       phrase = t("matches_company_accepted_your_job");
            }
        }

        if (job_owner == "party") {
            if (itemData.type == "application") {

                navigate("/app/view/application/"+itemData.id)
         //       phrase = t("matches_company_accepted_your_developers");
            }
            if (itemData.type == "proposal") {
          //      phrase = t("matches_you_accepted_party_job");
                navigate("/app/view/proposal/"+itemData.id)
            }
        }
    }

    const combinedOnClick = (event: React.MouseEvent) => {
        handleInternalClick();
        if (onClick) onClick(event);
    };

    if (!company) {
        console.log("Missing company");
        return <></>;
    }

    if (!company.profilePic) {
        company.profilePic = "";
    }

    let uc: any = ap.getUserCompany();

    return (
        <>
            <Table.Tr
                onClick={combinedOnClick}
                style={{
                    cursor: "pointer",
                    backgroundColor: hoveredRow ? "#f1f1f1" : "transparent",  // Highlight row if any row is hovered

                }}
                onMouseEnter={() => setHoveredRow(item.id)}  // Set the hovered row
                onMouseLeave={() => setHoveredRow(null)}   // Remove hover effect when mouse leaves
            >
                <Table.Td>
                    <Avatar size={40} src={company.profilePic}/>
                </Table.Td>
                <Table.Td>
                    <Text fw={700} tt="capitalize">
                        {company.name}
                    </Text>
                </Table.Td>
                <Table.Td>{company.vat}</Table.Td>
                <Table.Td>{company.type}</Table.Td>
                <Table.Td>{company.size}</Table.Td>
                <Table.Td>
                    <CompanySocials {...company.socials} />
                </Table.Td>
                <Table.Td>
                    {itemData.meta_updated_at && (
                        <DateTag date={itemData.meta_updated_at} withTime naked/>
                    )}
                </Table.Td>
            </Table.Tr>

            {showJson && (
                <Table.Tr
                    style={{
                        cursor: "pointer",
                        backgroundColor: hoveredRow ? "#f1f1f1" : "transparent",  // Highlight row if any row is hovered
                    }}
                    onMouseEnter={() => setHoveredRow(item.id)}  // Set the hovered row
                    onMouseLeave={() => setHoveredRow(null)}   // Remove hover effect when mouse leaves
                >
                    <Table.Td colSpan={10}>{JSON.stringify(itemData)}</Table.Td>
                </Table.Tr>
            )}

            {showProposalDetails && (
                <Table.Tr
                    onClick={combinedOnClick}
                    style={{
                        cursor: "pointer",
                        backgroundColor: hoveredRow ? "#f1f1f1" : "transparent",  // Highlight row if any row is hovered
                    }}
                    onMouseEnter={() => setHoveredRow(item.id)}  // Set the hovered row
                    onMouseLeave={() => setHoveredRow(null)}   // Remove hover effect when mouse leaves
                >
                    <Table.Td colSpan={10}>
                        <Table>
                            <thead>
                            <tr>
                                {showIconsColumn && <th></th>}
                                <th>Type</th>
                                <th>ID connection</th>
                                <th>Company From ID</th>
                                <th>Company To ID</th>
                                <th>Status</th>
                                <th>Created At</th>
                                <th>Updated At</th>
                                <th>Expiry Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                {showIconsColumn && (
                                    <td>
                                        {itemData.type === "application" && (
                                            <IconMail size={18} color="blue" style={{marginRight: 5}}/>
                                        )}
                                        {itemData.type === "proposal" && (
                                            <IconBriefcase size={18} color="green"/>
                                        )}
                                    </td>
                                )}
                                <td>{itemData.type}</td>
                                <td>{itemData.id}</td>
                                <td>{itemData.companyId}</td>
                                <td>{itemData.companyId}</td>
                                <td>{itemData.status}</td>
                                <td>{itemData.created_at}</td>
                                <td>{itemData.updated_at}</td>
                                <td>{itemData.expiry_date}</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Table.Td>
                </Table.Tr>

            )}


            {showJobDetails && job && (

                <Table.Tr onClick={combinedOnClick} style={{cursor: "pointer"}}>
                    <Table.Td colSpan={10}>

                        <MatchesJobDetail item={item}/>
                    </Table.Td>
                </Table.Tr>

            )}

            {showEmployeesDetails && job && (

                <Table.Tr onClick={combinedOnClick} style={{cursor: "pointer"}}>
                    <Table.Td colSpan={10}>

                        <MatchesEmployeesDetail item={item}></MatchesEmployeesDetail>
                    </Table.Td>
                </Table.Tr>

            )}

            <Table.Tr
                onClick={combinedOnClick}
                style={{
                    cursor: "pointer",
                    backgroundColor: hoveredRow ? "#f1f1f1" : "transparent",  // Highlight row if any row is hovered
                    borderBottomLeftRadius: "100px",  // Apply bottom left rounded corner
                    display: "none"
                }}
                onMouseEnter={() => setHoveredRow(item.id)}  // Set the hovered row
                onMouseLeave={() => setHoveredRow(null)}   // Remove hover effect when mouse leaves
            >
                <Table.Td colSpan={20} style={{
                    textAlign:"center", color:"red"
                }}></Table.Td>
            </Table.Tr>
            <Table.Tr
                onClick={combinedOnClick}
                style={{
                    cursor: "pointer",
                    backgroundColor: hoveredRow ? "#f1f1f1" : "transparent",  // Highlight row if any row is hovered
                }}
                onMouseEnter={() => setHoveredRow(item.id)}  // Set the hovered row
                onMouseLeave={() => setHoveredRow(null)}   // Remove hover effect when mouse leaves
            >


                <Table.Td colSpan={20}
                style={
                    {
                        textAlign:"left"
                    }
                }
                >
                    <div
                        style={{
                            borderRadius: "10px",  // Apply rounded corners to the outer div
                            border: "3px solid #3498db",  // Set border color and width (e.g., blue border with 3px width)
                            height: "40px",  // Set fixed height for the div
                            minWidth: "120px",  // Set minimum width for the div
                            width: "auto",  // Ensure the width adjusts to the content
                            display: "inline-flex",  // Use inline-flex to make the div only take the required space
                            alignItems: "center",  // Vertically center the phrase
                            justifyContent: "center",  // Optionally, horizontally center the content
                            padding: "0 10px",  // Add some padding if you need space around the content
                            textAlign: "center"
                        }}
                    >
                        {phrase}
                    </div>

                </Table.Td>
            </Table.Tr>


        </>
    );
}

export default MatchesTableRow;
