import { IconPlus } from "@tabler/icons-react";
import Actions from "../../../../components/Actions";
import Loader from "src/components/Loader";
import { EmployeeTable } from "../../../../components/tables/EmployeeTable";
import { useGET } from "src/hooks/useApi";
import Employee from "src/types/Employee.type";
import Config from "../../../../classes/application/Config";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import useIsMobile from "../../../../hooks/useIsMobile";
import { JobOfferStatus as JobOfferStatusType } from "../../../../types/JobOfferStatus.type";
import JobOfferListTopBarComponent from "../../../Employee/component/JobOfferList/JobOfferListTopBarComponent";
import Application from "../../../../classes/Application";
import JobOfferTable from "../../../../components/tables/JobOfferTable";
import JobOffer from "../../../../types/JobOffer.type";

const JobOfferListView = () => {

    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const direction = isMobile ? "column" : "row";
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [confirmTo, setConfirmTo] = useState("");
    const [confirmTitle, setConfirmTitle] = useState("");
    const [confirmMessage, setConfirmMessage] = useState("");
    const [confirmButtonConfirm, setConfirmButtonConfirm] = useState("");
    const [modeTo, setModeTo] = useState("");

    const cfg = Config.getInstance();

    let app = Application.getInstance();
    if (cfg.hasProgressiveRegister() && !app.hasCompany()) {
        let data: JobOffer[] = [];
        return (
            <>
                <JobOfferListTopBarComponent></JobOfferListTopBarComponent>
                <JobOfferTable jobOffers={data} />
            </>
        );
    }

    const { data, isLoading, error } = useGET<JobOffer[]>("res/job");
    return (
        <>
            <Loader isLoading={isLoading} error={error} hasData={!!data}>
                <Actions
                    title="my_job_offers"
                    actions={[{ to: "/app/add/job", label: "add_new", Icon: IconPlus }]}
                    infoboxCode={"infobox_jobs"}
                />
                <JobOfferTable jobOffers={data} />
            </Loader>
        </>
    );
};

export default JobOfferListView;
