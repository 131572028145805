import {Button, Tooltip} from "@mantine/core";
import {modals} from "@mantine/modals";
import {IconCoins, IconX} from "@tabler/icons-react";
import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useUser from "src/hooks/useUser";
import {UserRole} from "src/types/UserRole";
import hasCompany from "src/utils/hasCompany";
import {Url} from "../lib/http/Url";

interface Props {
    label: string;
    desc: string;
    cost: number;
    tooltip?: string;
    onSuccess: (ev: never) => void;
    onCancel?: () => void;
    disabled?: boolean;
}

/**
 * this button will show a confirmation modal before
 * buying things with credits, clearly showing the cost
 * of the item and the current balance
 * if the user accepts, it will proceed with the acquisition
 *
 * NOTE the buy logic is not managed here. it must be done
 * in the success handler flow, this component will deal
 * only with communication with the user
 */
const SpendCreditsButton = (props: Props) => {

    const {
        label,
        cost,
        desc,
        onSuccess,
        onCancel,
        tooltip,
        disabled = false,
    } = props;
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {user} = useUser();

    const {balance, isReadOnly, canSpend} = useMemo(() => {
        const balance = user?.Company?.account_credits ?? 0;
        const canSpend = hasCompany(user);

        const isReadOnly = user?.role === UserRole.ReadOnly

        //@todo it will be needed to check company account activation issue
        /*
        const isReadOnly =
          user?.role === UserRole.ReadOnly ||
          (user?.Company?.account_activation ?? 0) < 100;
        */


        return {
            balance,
            isReadOnly,
            canSpend,
        };
    }, [user]);


    let url = Url.fromBrowser();
    let texp: boolean = false;

    if (url.getParam("testexpiry")) {
        texp = true;
    }
    // readonly mode
    if (isReadOnly) {
        return (
            <Tooltip label={t("readonly_mode_desc")} opened withArrow>
                <Button disabled rightSection={<IconX/>}>
                    {t(label)}
                </Button>
            </Tooltip>
        );
    }

    // disabled action
    if (cost < 0 || isReadOnly) {
        return (
            <Tooltip label={t("temp_disabled_desc")} opened withArrow>
                <Button disabled rightSection={<IconX/>}>
                    {t(label)}
                </Button>
            </Tooltip>
        );
    }

    // free action (no modal required)
    if (cost === 0) {
        return (
            <Tooltip label={t(tooltip || desc)} opened withArrow>
                <Button onClick={onSuccess}>{`${t(label)}`}</Button>
            </Tooltip>
        );
    }

    const showModal = () => {
        modals.openContextModal({
            modal: "spendCredits",
            title: t("spend_credits_confirm_title"),
            innerProps: {
                cost,
                balance,
                reason: t(desc),
                onCancel,
                onBuyMore: () => {
                    navigate("/app/credits");
                },
                onSuccess,
            },
        });
    };

    return (
        <Tooltip label={t(tooltip || desc)}>
            <Button
                onClick={showModal}
                rightSection={<IconCoins/>}
                disabled={!canSpend || disabled}
            >
                {`${t(label)} (${cost} credits) `}
            </Button>
        </Tooltip>
    );
};

export default SpendCreditsButton;
