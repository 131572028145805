import { Box, Flex, Group, Skeleton, Stack, Text, Title, useMantineTheme } from "@mantine/core";
import CountryIcon from "../../../components/badges/CountryIcon";
import Company from "src/types/Company.type";
import { IconBuildingFactory2 } from "@tabler/icons-react";
import Avatar from "../../../components/Avatar";
import CompanyStatus from "../../../components/CompanyStatus";
import Description from "../../../components/Description";
import { useTranslation } from "react-i18next";
import useIsMobile from "src/hooks/useIsMobile";
import { useNavigate } from "react-router-dom";
import "./CompanyBaseComponent.css";
import Config from "../../../classes/application/Config";

interface Props {
    company?: Partial<Company>;
}

const CompanyBaseComponent = ({ company }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    if (!company) {
        return null;
    }

    let cfg = Config.getInstance();
    let isPublic = cfg.isCurrentPublicUrl();

    if(isPublic) {
        company.name= "Aiekeda Leofarc";
        company.desc= "Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.\n" +
            "\n";

    }
    let onclick = function () {
        if (isPublic) {
            return;
        }
        navigate(`/app/view/company/${company.id}`);
    };

    const handleRegisterClick = () => {
        window.open("/app/signup","_self"); // Change this to the actual registration route
    };
    const theme = useMantineTheme();
    return (
        <div className="company-card" style={{ position: "relative" }}>
            {isPublic && (
                <div
                    style={{
                        position: "absolute",
                        bottom: 40, // Adjust position to the bottom
                        left: 0,
                        width: "100%",
                        padding: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        textAlign: "center",
                        backgroundColor: theme.colors[theme.primaryColor][6], // Use primary color from Mantine theme
                        zIndex: 999,
                        cursor: "pointer",

                    }}
                    onClick={handleRegisterClick}
                >
                    {
                        t("register_to_see_details")
                    }
                </div>
            )}
            <Flex
                my="md"
                gap="sm"
                wrap="wrap"
                direction="column"
                justify="center"
                align="center"
                onClick={onclick}
                style={{ cursor: "pointer", textAlign: "center" }}
            >
                <Box mx="auto">
                    <Avatar size={200} src={company.profilePic}>
                        <IconBuildingFactory2 size={90} />
                    </Avatar>
                </Box>

                <Stack maw="95%">
                    <Title tt="capitalize" style={isPublic ? { filter: "blur(5px)" } : {}}>
                        {company.name || ""}
                    </Title>
                    <CompanyStatus company={company as Company} />
                    <Group justify="center">
                        {company.loc_nation && <CountryIcon id={company.loc_nation} />}
                        <Text size="small" tt="capitalize" style={isPublic ? { filter: "blur(5px)" } : {}}>
                            {company.size} {company.type}
                        </Text>
                    </Group>
                    <Description text={company.desc || ""} style={isPublic ? { filter: "blur(5px)" } : {}} />
                </Stack>
            </Flex>
        </div>
    );
};

export default CompanyBaseComponent;
