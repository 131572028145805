import _size from "lodash/size";
import { useState } from "react";
import { TooltipGroup, AvatarGroup, Flex, Tooltip, Box, Button, Code } from "@mantine/core";
import { TooltipAvatar } from "src/components/badges/TooltipAvatar";
import Employee from "src/types/Employee.type";
import Avatar from "../Avatar";

interface Props {
  data: Employee[];
  cap?: number;
}

export const EmployeeList = ({ data, cap = 3 }: Props) => {
  //const [showJsonDump, setShowJsonDump] = useState(false);

  const visibleEls = data.slice(0, cap);
  const hiddenEls = data.slice(cap);

  let showJsonDump:boolean=false;
  const processedData = visibleEls.map(({ name, profilePic }) => ({ name, profilePic }));

  return (
      <TooltipGroup>
        <AvatarGroup>
          <Flex align="center">
            {visibleEls.map(({ name, profilePic }, index) => (
                <Box key={index}>
                  <TooltipAvatar label={name} src={profilePic} />
                  {showJsonDump && (
                    <>
                      <Code block>{JSON.stringify({ name, profilePic }, null, 2)}</Code>
                      <Code block>{JSON.stringify(data, null, 2)}</Code>
                    </>
                  )}
                </Box>
            ))}
            {_size(hiddenEls) > 0 && (
                <Tooltip
                    withArrow
                    label={
                      <>
                        {hiddenEls.map(({ name }, index) => (
                            <Box key={index}>{name}</Box>
                        ))}
                      </>
                    }
                >
                  <Avatar size={36}>{`+${_size(hiddenEls)}`}</Avatar>
                </Tooltip>
            )}
          </Flex>
        </AvatarGroup>
      </TooltipGroup>
  );
};