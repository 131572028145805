import CompanyView from "src/components/view/Company.view";
import useUser from "src/hooks/useUser";
import usePageView from "../hooks/usePageView";
import {Url} from "../lib/http/Url";

export default function Dashboard() {
  const { user } = useUser();
  usePageView("Dashboard");
  let url =  Url.fromBrowser();
  if(url.pathNameContains("app/signup")) {
    history.replaceState('','',"/app/dashboard");
  }

  return <CompanyView company={user?.Company} />;
}
