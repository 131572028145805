import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import AuthProvider from "./providers/auth.provider";
import { version } from "../package.json";
import { MantineProvider } from "@mantine/core";
import { autom8dealTheme } from "./providers/theme-autom8deal";
import { ModalsProvider } from "@mantine/modals";
import modals from "./providers/modals";
import { initLanguage } from "./helpers/initLanguage";
import { Authenticator, ThemeProvider } from "@aws-amplify/ui-react";
import AppComponents from "./AppComponents";
import theme from "./providers/theme-autom8deal-auth";

import * as Sentry from "@sentry/react";
import { NotificationProvider } from "./providers/notification.provider";
import { TourProvider } from "@reactour/tour";
import ReactGA from "react-ga4";
import Config from "./classes/application/Config";
import { Helmet } from "react-helmet";
import { FacebookPixelScript } from "./components/FacebookPixelScript";
import Application from "./classes/Application"; // import Helmet

declare let __BUILD_DATE__: string;
declare let __AMPLIFY_CONFIG__: string;

console.log(`autom8deal frontend`);
console.log(`version: ${version}`);
console.log(`apiUrl: ${import.meta.env.VITE_API_URL}`);
console.log(`env mode: ${import.meta.env.MODE}`);
console.log(`env_CFG: ${import.meta.env.VITE_CFG}`);
console.log(`env_INFOBOXES ${import.meta.env.VITE_INFOBOXES}`);
console.log(`build: ${__BUILD_DATE__}`);

if (!import.meta.env.DEV) {
    Sentry.init({
        dsn: "https://15fe51f1bba8f6b3cddd2b1664b0065f@o4507049995392.ingest.us.sentry.io/4507051458560000",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        environment: import.meta.env.MODE,
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
            "localhost",
            "https://api.autom8deal.com",
            /^https:\/\/api\.autom8deal\.com/,
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

initLanguage().then(() => {
    const cfg = Config.getInstance();
    const isChatbot = window.location.pathname.includes("/chatbot");
    const isChatbotConfig = window.location.pathname.includes("/ik4kf884ax91aic");
    const isPublicRoute = cfg.isCurrentPublicUrl();
    let isAuth = Application.getInstance().isAuth();

    let isJubendaEnabled = cfg.isJubendaEnabled();

    const renderJubendaScripts = () => {
        if (isJubendaEnabled) {
            return (
                <>
                    {/* Iubenda Scripts */}
                    <script type="text/javascript">
                        {`
                        var _iub = _iub || [];
                        _iub.csConfiguration = {
                            askConsentAtCookiePolicyUpdate: true,
                            floatingPreferencesButtonDisplay: "bottom-left",
                            perPurposeConsent: true,
                            siteId: 3606676,
                            whitelabel: false,
                            cookiePolicyId: 83589259,
                            lang: "it",
                            banner: {
                                acceptButtonDisplay: true,
                                closeButtonRejects: true,
                                customizeButtonDisplay: true,
                                explicitWithdrawal: true,
                                listPurposes: true,
                                position: "float-bottom-center",
                                showTitle: false
                            }
                        };
                        `}
                    </script>
                    <script>
                        window.helmet_in_page = 1;
                    </script>
                    <script type="text/javascript" src="https://cs.iubenda.com/autoblocking/3606676.js"></script>
                    <script
                        type="text/javascript"
                        src="//cdn.iubenda.com/cs/iubenda_cs.js"
                        charSet="UTF-8"
                        async
                    ></script>
                </>
            );
        }
        return <script>
            window.helmet_in_page = 1;
        </script>
            ;
    }
    let initial_state: 'signUp' | 'signIn' | 'forgotPassword' | undefined = undefined;
    if (location.href.includes("app/signup")) {
        initial_state = "signUp";
    }
    if(location.href.includes("app/signup")) {
        initial_state="signUp";
    }

    ReactDOM.createRoot(document.getElementById("root")!).render(
        <React.StrictMode>
            <Helmet>
                {/* Removed Facebook Pixel Script and Noscript from Helmet */}
                {renderJubendaScripts()}
            </Helmet>

            {/* Facebook Pixel Script Component */}
            <FacebookPixelScript />

            <MantineProvider theme={autom8dealTheme} defaultColorScheme="light">
                <ModalsProvider modals={modals}>
                    <ThemeProvider theme={theme}>
                        {isChatbot || isChatbotConfig || isPublicRoute ? (
                            <AuthProvider>
                                <NotificationProvider>
                                    <TourProvider steps={[]} showCloseButton={false} padding={10}>
                                        <App />
                                    </TourProvider>
                                </NotificationProvider>
                            </AuthProvider>
                        ) : (
                            <Authenticator initialState={initial_state} components={AppComponents}>
                                <AuthProvider>
                                    <NotificationProvider>
                                        <TourProvider steps={[]} showCloseButton={false} padding={10}>
                                            <App />
                                        </TourProvider>
                                    </NotificationProvider>
                                </AuthProvider>
                            </Authenticator>
                        )}
                    </ThemeProvider>
                </ModalsProvider>
            </MantineProvider>
        </React.StrictMode>
    );
});
